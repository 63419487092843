<div class="wrapper" id="wrapper">
    <div class="spinners_main_div" *ngIf="showSpinnerFlag">
        <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="container" id="contentToConvert" >
        <div class="header">
            <div class="headerLogo">
                <!-- <img  src="../../../assets/img/logo_CJBS_Updated.png"> -->
                <img style="margin-left: 10px;margin-top: 10px;" (load)="imageCheck()" [src]=game_logo>
                <img style="height: 1cm;" src="../../../assets/img/esg-logo.svg"/>
            </div>
            <div class="headerName" >
                <p>Name: <b>{{studentName}}</b></p>
                <p>Session Date: <b>{{sessionDate}}</b></p>
                <p>Played On:<b>{{player_date}}</b></p>
                <p>Duration: <b>{{durationMinutes}} minutes and {{durationSeconds}}seconds</b>
            </div>
        </div>

        <div *ngIf="gameType === 1 || gameType === 2">
            <div class="containerBody">
                <div class="performanceSummary">
                    <b style="font-size: 15px;">Performance Summary</b>
                    <div class="PSBody">
                        <div class="PSBodyLeft">
                            <!--Effort Points Spend--->
                            <div class="PSTopics">
                                <div class="PSNumber"> 1)</div>
                                <div class="PSContent">
                                    <div class="PSContentTop">
                                        <p style="font-size: 15px;">Effort Points Spend: <span style="color: #057AA7;">{{effortPoints}}</span>/{{totalEffortPoints}}</p>
                                    </div>
                                    <div class="PSContentBottom">
                                        The average effort points spent by your cohort were <span style="color: #057AA7;"> {{avg_effortPoints}}</span>
                                    </div>
                                </div>
                            </div>
                             <!--Brand Value--->
                            <div class="PSTopics">
                                <div class="PSNumber"> 2)</div>
                                <div class="PSContent">
                                    <div class="PSContentTop">
                                        <p style="font-size: 15px;">Brand Value: <span style="color: #057AA7;"> {{brandValue}}</span></p>
                                    </div>
                                    <div class="PSContentBottom">
                                        The average brand value achieved by other participants is <span style="color: #057AA7;"> {{avg_brandValue}}</span>
                                    </div>
                                </div>
                            </div>
                             <!--ESG Score--->
                            <div class="PSTopics">
                                <div class="PSNumber"> 3)</div>
                                <div class="PSContent">
                                    <div class="PSContentTop">
                                        <p style="font-size: 15px;">ESG Score: <span style="color:#057AA7;">{{ESGScore}}</span></p>
                                    </div>
                                    <div class="PSContentBottom">
                                        The average ESG score achieved by your cohort is <span style="color: #057AA7;">{{avg_esgScore}}</span>
                                    </div>
                                </div>
                            </div>
                             <!--Other Score--->
                            <div *ngIf="otherPoints !== null && otherPoints !== undefined">
                                <div class="PSTopics">
                                    <div class="PSNumber"> 4)</div>
                                    <div class="PSContent">
                                        <div class="PSContentTop">
                                            <p style="font-size: 15px;">Other Score: <span style="color: #057AA7;"> {{otherPoints}}</span></p>
                                        </div>
                                        <div class="PSContentBottom">
                                            The average ESG score achieved by your cohort is <span style="color: #057AA7;"> {{avg_otherPoints}}</span>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <!-- <div class="PSBodyRight">
                            <b>You acheived an ESG rating of {{esgGradeTxt}} while retaining a HIGH brand value within the allocated effort points.</b>
                        </div> -->
                    </div>
                </div>
                 <!--ESG SCORE--->
                <div class="esgScore">
                <div class="esgScoreTitle">ESG Score</div>
                <div class="ESGHeader">
                    <div class="circleContainer">
                        <!-- <apx-chart 
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [labels]="label"
                        [dataLabels]="chartOptions.dataLabels"
                        [title]="chartOptions.title"
                        [legend]="pieLegend"
                        [plotOptions]="chartOptions.plotOptions"
                        [colors]="totalLicensecolors"
                    >
                    </apx-chart> -->
                    <div class="circle" id="circle1"> {{ESGScore}}</div>
                    </div>
                    <div class="ESGMarks">
                        <p class="ESGMarksHeader" style="font-size: 16px;font-weight: 300;margin-bottom: 10px;"><b>ESG Score</b></p>
                        <p>The highest ESG Score in your cohort is <span style="color: red;">{{highest_esgScore}}</span></p>
                    </div>
                </div>
                <p style="text-align: justify;margin-bottom: 10px;font-size:12px;line-height: 20px;padding: 4px;">
                    <!-- An ESG score assigns a quantitative metric to the
                    environmental, social and governance (ESG) efforts
                    undertaken by an organization. Measured on a scale of 1 to
                    100, your ESG score will determine your ESG rating at the
                    end of the game. Based on your ESG Score, your
                    organisation would receive an ESG rating. ESG reporting is
                    now required for major UK companies. -->
                    {{esg}}
                </p>
                <!-- <p style="text-align: justify;margin-bottom: 10px;">
                   MSCI ESG RATING: Please refer to the chart below to calculate your ESG rating. You started at BBB Rating.
                </p>
                <div class="ESGScores">
                    <p class="ESGScoresHeader"><b> MSCI RATING : {{esgGradeTxt}}</b></p>
                </div>
                <div class="ESGMeter">
                    <div class="meterContainer">
                        <div class="invMeter meterLeft" [ngClass]="esgGrade==7?'selectedInvMeter':''"  style="background-color: #6aa84f;" >AAA</div>
                        <div class="meterRight">80 to 100</div>
                    </div>
                    <div class="meterContainer">
                        <div class="invMeter meterLeft" [ngClass]="esgGrade==6?'selectedInvMeter':''"  style="background-color: #b6d7a8;" >AA</div>
                        <div class="meterRight">65 to 79</div>
                    </div>
                    <div class="meterContainer">
                        <div class="invMeter meterLeft" [ngClass]="esgGrade==5?'selectedInvMeter':''"  style="background-color: #fff2cc;" >A</div>
                        <div class="meterRight">51 to 64</div>
                    </div>
                    <div class="meterContainer">
                        <div class="invMeter meterLeft" [ngClass]="esgGrade==4?'selectedInvMeter':''"  style="background-color: #fdd966;" >BBB</div>
                        <div class="meterRight">36 to 50</div>
                    </div>
                    <div class="meterContainer">
                        <div class="invMeter meterLeft" [ngClass]="esgGrade==3?'selectedInvMeter':''"  style="background-color: #f6b26b;" >BB</div>
                        <div class="meterRight">21 to 35</div>
                    </div>
                    <div class="meterContainer">
                        <div class="invMeter meterLeft" [ngClass]="esgGrade==2?'selectedInvMeter':''"  style="background-color: #f6982d;" >B</div>
                        <div class="meterRight">10 to 20</div>
                    </div>
                    <div class="meterContainer">
                        <div class="invMeter meterLeft" [ngClass]="esgGrade==1?'selectedInvMeter':''"  style="background-color: #cd3f23;" >CCC</div>
                        <div class="meterRight">0 to 9</div>
                    </div>
                 
                </div> -->
                </div>
                <!--EFFORT POINTS--->
                <div class="effortPoints">
                <div class="effortTitle">EFFORT POINTS</div>
                <!-- <p class="ESGMarksHeader"><b>EFFORT POINTS</b></p> -->
                <div class="effortHeader">
                    <div class="circleContainer">
                        <div class="circle" id="circle2">{{effortPoints}}</div>
                    </div>
                    <div class="ESGMarks">
                        <p class="ESGMarksHeader" style="font-size: 16px; font-weight: 300;margin-bottom: 10px;">Effort points used</p>
                        <p>The highest Effort Points in your cohort is <span style="color: red;">{{highest_effortPoints}}</span></p>
                    </div>
                </div>  
                <div class="effortBottom">
                    <ng-container *ngFor="let word of splitOriginalData(effort)">
                        <span [ngStyle]="{'color': (word === effortStatus|| word == avg_effortPoints) ? 'red' : 'inherit'}">
                            {{ word }}
                        </span>
                    </ng-container>
                </div>
                </div>
                <!--BRAND VALUE change brandValue to otherPoints --->
                <div [ngClass]="brandValue === null ? 'brandValue1' : 'brandValue2'"> 
                <div class="brandTitle">BRAND VALUE</div>
                <div class="brandHeader">
                    <div class="circleContainer">
                        <div class="circle" id="circle3">{{brandValue}}</div>
                    </div>  
                    <div class="ESGMarks">
                        <p class="ESGMarksHeader" style="font-size: 16px; font-weight: 300;margin-bottom: 10px;">Brand Value</p>
                        <p>The highest Brand Value in your cohort is <span style="color: red;">{{highest_brandValue}}</span></p>
                    </div>
                </div>
                <div class="brandRight">
                    <ng-container *ngFor="let word of splitOriginalData(brand)">
                        <span [ngStyle]="{'color': (word === brandStatus|| word == avg_brandValue) ? 'red' : 'inherit'}">
                            {{ word }}
                        </span>
                    </ng-container>
                </div>
                <!-- <p><b>PRO TIP :</b>Hereʼs a Brand Value Model that reflects how
                    consumers make decisions:</p>
                <img  style="align-self: center;margin-top: 1rem; width: 85%;" src="../../../assets/img/brandValue.png"/>
                    <p class="scource">Source: Ipsosʼ Brand Value Creator (BVC)</p> -->
                </div>
                <div *ngIf="otherPoints !== null && otherPoints !== undefined" class="otherPoints">
                <div class="otherTitle">OTHER POINTS</div>
                <div class="otherHeader">
                    <div class="circleContainer">
                        <div class="circle" id="circle3">{{brandValue}}</div> 
                    </div>  
                    <div class="ESGMarks">
                        <p class="ESGMarksHeader" style="font-size: 16px; font-weight: 300;margin-bottom: 10px;">Other Points</p>
                        <p>The highest ESG Score in your cohort is <span style="color: red;">{{highest_esgScore}}</span></p>
                    </div>
                </div>
                <p style="text-align: justify;margin-bottom: 10px;font-size:12px;line-height: 20px;">
                    {{esg}}
                </p>
                </div>
            </div>
        </div>

        <div *ngIf="gameType > 2">
            <div class="containerBody">
                <div class="performanceSummary">
                    <b style="font-size: 15px;">Performance Summary</b>
                    <div class="PSBody">
                        <div class="PSBodyLeft">
                            <div class="PSTopics">
                                <div class="PSNumber"> 1)</div>
                                <div class="PSContent">
                                    <div class="PSContentTop">
                                        <p style="font-size: 15px;">{{customValue1}} Spend: <span style="color: #057AA7;">{{value1}}</span></p>
                                    </div>
                                    <div class="PSContentBottom">
                                        The average {{customValue1}} points spent by your cohort were <span style="color: #057AA7;"> {{avg_value1}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="PSTopics">
                                <div class="PSNumber"> 2)</div>
                                <div class="PSContent">
                                    <div class="PSContentTop">
                                        <p style="font-size: 15px;">{{customValue2}} spend : <span style="color: #057AA7;">{{value2}}</span></p>
                                    </div>
                                    <div class="PSContentBottom">
                                        The average {{customValue2}} points spent by your cohort were <span style="color: #057AA7;"> {{avg_value2}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="PSTopics">
                                <div class="PSNumber"> 3)</div>
                                <div class="PSContent">
                                    <div class="PSContentTop">
                                        <p style="font-size: 15px;">{{customValue3}} spend : <span style="color: #057AA7;">{{value3}}</span></p>
                                    </div>
                                    <div class="PSContentBottom">
                                        The average {{customValue3}} points spent by your cohort were <span style="color: #057AA7;"> {{avg_value3}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="customValue4 !== null && customValue4 !== undefined">
                                <div class="PSTopics">
                                    <div class="PSNumber"> 4)</div>
                                    <div class="PSContent">
                                        <div class="PSContentTop">
                                            <p style="font-size: 15px;">{{customValue4}} spend : <span style="color: #057AA7;">{{value4}}</span></p>
                                        </div>
                                        <div class="PSContentBottom">
                                            The average {{customValue4}} points spent by your cohort were <span style="color: #057AA7;"> {{avg_value4}}</span>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <!--Custom Value--->
                <div class="esgScore">
                    <div class="esgScoreTitle">{{customValue1}}</div>
                    <div class="ESGHeader">
                    <div class="circleContainer">
                    <div class="circle" id="circle1"> {{value1}}</div>
                    </div>
                    <div class="ESGMarks">
                        <p class="ESGMarksHeader" style="font-size: 16px;font-weight: 300;margin-bottom: 10px;"><b>{{customValue1}}</b></p>
                        <p>The highest {{customValue1}} Score in your cohort is <span style="color: red;">{{max_value1}}</span></p>
                    </div>
                    </div>
                    <div class="effortBottom">
                        <ng-container *ngFor="let word of splitOriginalData(custom1)">
                            <span [ngStyle]="{'color': (word === customStatus1|| word == avg_value1) ? 'red' : 'inherit'}">
                                {{ word }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <!--EFFORT POINTS--->
                <div class="effortPoints">
                    <div class="effortTitle">{{customValue2}}</div>
                    <div class="effortHeader">
                        <div class="circleContainer">
                            <div class="circle" id="circle2">{{value2}}</div>
                        </div>
                        <div class="ESGMarks">
                            <p class="ESGMarksHeader" style="font-size: 16px; font-weight: 300;margin-bottom: 10px;">{{customValue2}}</p>
                            <p>The highest {{customValue2}} in your cohort is <span style="color: red;">{{max_value2}}</span></p>
                        </div>
                    </div>  
                    <div class="effortBottom">
                        <ng-container *ngFor="let word of splitOriginalData(custom2)">
                            <span [ngStyle]="{'color': (word === customStatus2|| word == avg_value2) ? 'red' : 'inherit'}">
                                {{ word }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div [ngClass]="otherPoints === null ? 'brandValue1' : 'brandValue2'"> 
                   <div class="brandTitle">{{customValue3}}</div>
                   <div class="brandHeader">
                       <div class="circleContainer">
                           <div class="circle" id="circle3">{{value3}}</div>
                       </div>  
                       <div class="ESGMarks">
                           <p class="ESGMarksHeader" style="font-size: 16px; font-weight: 300;margin-bottom: 10px;">{{customValue3}}</p>
                           <p>The highest {{customValue3}} Score in your cohort is <span style="color: red;">{{max_value3}}</span></p>
                       </div>
                   </div>
                   <div class="brandRight">
                       <ng-container *ngFor="let word of splitOriginalData(custom3)">
                            <span [ngStyle]="{'color': (word === customStatus3|| word == avg_value3) ? 'red' : 'inherit'}">
                               {{word}}
                            </span>
                       </ng-container>
                   </div>
                </div>
                <div *ngIf="otherPoints !== null && otherPoints !== undefined" class="otherPoints">
                    <div class="otherTitle">{{customValue4}}</div>
                    <div class="otherHeader">
                        <div class="circleContainer">
                            <div class="circle" id="circle3">{{value4}}</div> 
                        </div>  
                        <div class="ESGMarks">
                            <p class="ESGMarksHeader" style="font-size: 16px; font-weight: 300;margin-bottom: 10px;">{{customValue4}}</p>
                            <p>The highest {{customValue4}} Score in your cohort is <span style="color: red;">{{max_value4}}</span></p>
                        </div>
                    </div>
                    <div class="brandRight">
                        <ng-container *ngFor="let word of splitOriginalData(custom4)">
                             <span [ngStyle]="{'color': (word === customStatus4|| word == avg_value4) ? 'red' : 'inherit'}">
                                {{word}}
                             </span>
                        </ng-container>
                    </div>
                </div>
            </div>        
        </div>
    </div>

    <div class="container" id="contentToConvert2" style="margin-top: 10px;" *ngIf="showPageMentors">
            <div class="header">
                <div class="headerLogo">
                    <img style="margin-left: 10px;margin-top: 10px;" (load)="imageCheck()" [src]="game_logo">
                    <img style="height: 1cm" src="../../../assets/img/esg-logo.svg"/>
                </div>
            <div class="headerName" >
                <p>Name: <b>{{studentName}}</b></p>
                <p>Session Date: <b>{{sessionDate}}</b></p>
            </div>
            </div>
            <div class="containerBodyBottom" >
                
                <div class="mentorContainer">
                    <div class="mentor" *ngFor="let links of mentorData;let i=index">
                        <div class="mentorNames">
                            <img (load)="imageCheck()" [src]="mentorImage[i]?.image"/>
                            <div class="name">{{mentorImage[i]?.name}}</div>
                        </div>        

                            <div style="min-height: 1cm;max-height: 8cm;overflow: auto;" class="desc">{{mentorImage[i]?.desc}}</div>
                    </div>
                </div>
               
            </div>
        </div>

        <div class="container" style="margin-top: 10px;" id="contentToConvert3">
            <div class="header" style="height: 2cm;">
                <div class="headerLogo">
                    <img style="margin-left: 10px;margin-top: 10px;height: 1cm;" (load)="imageCheck()" [src]=game_logo>
                    <img style="height: 1cm;" src="../../../assets/img/esg-logo.svg"/>
                </div>
                <div class="headerName" >
                    <p>Name: <b>{{studentName}}</b></p>
                    <p>Session Date: <b>{{sessionDate}}</b></p>
                </div>
            </div>
            <div class="containerBodyBottom">
             
                <div class="additionalResource">
                    <p><b>Additional reading resources:</b></p>
                    <div class="links">
                        <div class="linkContent" *ngFor="let data of linkData" >
                            <div class="linkLeft">{{data.text}}</div>
                            <div class="linkRight"><a href={{data.link}}>{{data.link}}</a></div>
                        </div>
                    </div>
                </div>
                <!-- <p>Access the ESG career strategy workbook <a href="{{ESG_career_workbook}}">here</a></p> -->
                <p>Please spare 30 seconds to provide feedback for the ESG game - <a href="{{ESG_feedback_googleForm}}" target="_blank">Click Here</a></p>            
            </div>
        </div>
</div>
