import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ReportComponent } from '../../reports/report.component';
import { HttpService } from 'src/app/services/https/http.service';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { csvParseRows, interval } from 'd3';
import { parse } from 'mathjs';
import { resolve } from 'path';
const emailRegex = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, "gm");
@Component({
  selector: 'app-game-report',
  templateUrl: './game-report.component.html',
  styleUrls: ['./game-report.component.css']
})
export class GameReportComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private toast: ToastrService,
    private http: HttpService,
    private dataShare: DataShareService,
    private router: Router,
    private toster: ToastrService
  ) { }

  ngOnInit(): void {
    this.userId = sessionStorage.getItem('user_id')
    if (!this.userId) {
      this.router.navigate(['/login']);
      this.toast.warning("Something went wrong. Please Login Again", '', { timeOut: 2000 })
    }
    if (this.router.url == '/superAdminDashboard') this.adminFlag = true
    this.getReportData()
  }
  adminFlag: boolean = false
  isGameSummary: any = false;
  isEpgSummary: any = false
  sessionCode: any;
  userId: any = sessionStorage.getItem('user_id');
  selectedFilter: any = 'all';
  displayedColumns: string[] = ['report_name', 'game_name', 'univ_name', 'univ_id', 'email', 'rename', 'view'];
  dataBM: any[] = []
  data: any;
  dataSource: any = new MatTableDataSource();
  filterByStatus(data: any) {
    this.selectedFilter = data == 1 ? 'ESG' : 'EPG'
    if (data == 'all') {
      this.dataSource = this.data
      this.selectedFilter = 'all'
      return
    }
    let tempdata: any[] = [];
    this.data.map((res: any) => {
      if (res.game_type == data)
        tempdata.push(res)
    })
    this.dataSource = tempdata

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  togleGameSummary(id: any, type: any, code: any) {
    console.log(id, type, code)
    if (code)
      this.sessionCode = code
    if (type != 2) {
      this.isGameSummary = !this.isGameSummary
      this.dataShare.reportId = id
    }
    else
      this.isEpgSummary = !this.isEpgSummary
  }
  confirmDelete() {

  }
  setStatus(status: any, element: any) {

  }
  editTemplate(element: any) {
    const dialogRef = this.dialog.open(DialogueEditTemplate, {
      disableClose: true,
      hasBackdrop: true,
      data: {
      }
    })
  }
  getReportData() {
    this.http.getSaReport(parseInt(this.userId)).subscribe((res: any) => {
      if (res['status']) {
        this.dataSource.data = res['data']
        this.data = res['data']
      } else if (res === null) {
        this.toast.warning(res.message, '', { timeOut: 2000 })
      }
    })
  }

  renameReport(element: any) {
    const dialogRef = this.dialog.open(DialogueRenameReport, {
      disableClose: false,
      hasBackdrop: true,
      data: {
        element
      }
    }).afterClosed().subscribe((res: any) => {
      if (res)
        this.getReportData()
    })

  }
  deleteGameReport(session_id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: { heading: 'Are you sure to delete this game report ?' }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.http.deleteGameReport(session_id).subscribe((res: any) => {
          if (res['status']) {
            if (this.selectedFilter == 'all')
              this.http.getSaReport(parseInt(this.userId)).subscribe((res: any) => {
                if (res['status']) {
                  this.dataSource = res['data']
                  this.data = res['data']
                } else if (res === null) {
                  this.toast.warning(res.message, '', { timeOut: 2000 })
                }
              })
            else {
              this.http.getSaReport(parseInt(this.userId)).subscribe((res: any) => {
                if (res['status']) {
                  this.dataSource = res['data']
                  this.data = res['data']
                  this.filterByStatus(this.selectedFilter == 'ESG' ? 1 : 2)
                } else if (res === null) {
                  this.toast.warning(res.message, '', { timeOut: 2000 })
                }
              })
            }
          } else {
            this.toster.error(res.message, '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
      }
    })
  }
}





@Component({
  selector: 'app-game-summary-game',
  templateUrl: './game.summary.component.html',
  styleUrls: ['./game-report.component.css']
})
export class GameSummaryComponent implements OnInit {
  @Output() messageEmitterSummary = new EventEmitter<any>();
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    private toast: ToastrService,
    private http: HttpService,
    private dataShare: DataShareService,
    public dialog: MatDialog,
    private router: Router,
    private toster: ToastrService,

  ) { }

  ngOnInit(): void {
    this.reportId = this.dataShare.reportId;
    if (this.router.url == '/superAdminDashboard') this.adminFlag = true
    this.getStudentsReport(this.reportId)
  }
  hideLoader(): void {
    const loader = document.querySelector('ng-http-loader') as HTMLElement;
    if (loader) {
      loader.style.display = 'none';
    }
  }

  showLoader(): void {
    const loader = document.querySelector('ng-http-loader') as HTMLElement;
    if (loader) {
      loader.style.display = 'block';
    }
  }
  adminFlag: boolean = false
  rounds: any = ['111', '111,', '333333'];
  src: any = ''
  reportId: any;
  fileData: any;
  status: any;
  selectedFilter: any = '';
  displayedColumns: string[] = ['sl_no', 'name', 'points', 'esg_score', 'brand_value', 'request', 'action'];
  dataBM: any[] = []
  dataSource: any = new MatTableDataSource();
  customDisplayColumn: any = []
  isCustomGame: boolean = false
  goBackSummary() {
    this.messageEmitterSummary.emit()
  }
  setStatus(data: any) {
    this.status = data
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getBrandValue(value: any) {
    if (value)
      return Math.ceil(((parseInt(value) - 32.5) / 64) * 100)
    return
  }
  getEsgScore(value: any) {
    if (value) {
      let val = Math.ceil(((parseInt(value) - 36.2) / 64) * 100)
      if (val > 100)
        val = 100
      return val
    }
    return
  }

  getStudentsReport(id: any) {
    this.http.getEsgSummaryPlayersList(id).subscribe((res: any) => {
      if (res['status']) {
        if (res['formula'].length == 0) {
          this.dataSource.data = res['data']
        }
        else {
          this.displayedColumns = ['sl_no', 'name', ...res['formula'].map(data => data.name), 'request', 'action']
          const endIndex = this.displayedColumns.length - 3;
          this.customDisplayColumn = res['formula'].map(data => ({
            name: data.name,
            id: data.id,
            is_curve_ball: data.is_curve_ball,
          }));
          console.log(this.customDisplayColumn, 'aaaa')
          this.dataSource.data = res['data']
          this.isCustomGame = true
        }
        this.dataSource.sort = this.sort
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  getCustomValueName(element: any, columnId: any) {
    // console.log(element,columnId)
    if (element['custom_values'].length > 0) {
      const customValue = element['custom_values'].find(data => data.formula_id === columnId);
      return customValue ? customValue.value : 0;
    }
    else {
      return 0
    }
  }
  downloadReport(element: any) {
    const dialogRef = this.dialog.open(ReportComponent, {
      disableClose: false,
      hasBackdrop: true,
      height: '90vh',
      data: {
        element,
        isDownload: true
      }
    })
  }
  viewReport(element: any) {
    console.log('element', element)
    const dialogRef = this.dialog.open(ReportComponent, {
      disableClose: false,
      hasBackdrop: true,
      height: '90vh',
      data: {
        element
      }
    }).afterClosed().subscribe((res: any) => {
      if (res)
        this.getStudentsReport(this.reportId)
    })
  }

  sendEmail(element: any) {
    if (element.email) {
      element.sendEmail = true
      this.viewReport(element)
    }
    else {
      const dialogRef = this.dialog.open(DialogueAddEmail, {
        disableClose: true,
        hasBackdrop: true,
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          element.email = res;
          element.sendEmail = true
          this.viewReport(element)
        }
      })
    }
  }

  updateProgress(totalReports: number): void {
    this.sendAllIndex++;
    this.progressValue = Math.round((this.sendAllIndex / totalReports) * 100);
  }

  sendAllIndex: number = 0;
  successReportCount: number = 0;
  failedReportCount: number = 0;
  isProcessing: boolean = false;
  progressValue: number = 0;
  intervalRef: any;

  sendAllReport() {
    this.isProcessing = true;
    this.hideLoader();
    this.sendAllIndex = 0;
    this.progressValue = 0;
    this.successReportCount = 0;
    this.failedReportCount = 0;
    sessionStorage.setItem('reportProgress', 'true');

    const pdfData = this.dataSource.data;
    const requestedPdfs = pdfData.filter((pdf) => (pdf.report_status != 'Completed') && (pdf.email != null));
    const totalPdf = requestedPdfs.length;
    this.sendAllIndex = 0;
    const sendNext = () => {
      if (this.sendAllIndex < totalPdf) {
        const allPdf = requestedPdfs[this.sendAllIndex];
        allPdf.sendAllEmail = true;

        const dialogRef = this.dialog.open(ReportComponent, {
          disableClose: false,
          hasBackdrop: true,
          height: '90vh',
          data: {
            element: allPdf
          }
        });
        dialogRef.afterClosed().subscribe((res: any) => {
          if (res) {
            this.successReportCount++
          } else {
            this.failedReportCount++;
            console.error('Failed to generate report for:', allPdf);
          }
          this.updateProgress(totalPdf);
          sendNext()
        });
      } else {
        this.isProcessing = false;
        this.showLoader();
        this.toster.success(`${this.successReportCount} report(s) sent successfully, ${this.failedReportCount} failed`);
        this.getStudentsReport(this.reportId);
        setTimeout(() => {
          document.body.click()
          this.isProcessing = false;
          this.showLoader();
        }, 500)
        this.ngOnInit();
        this.sendAllIndex = 0;
        sessionStorage.removeItem('reportProgress');
        
      }
    };
    sendNext();
  }
  isCancelled = false;
  async downloadAll() {
    this.hideLoader();
    this.isCancelled = false
    this.isProcessing = true;
    this.progressValue = 0;
    this.successReportCount = 0;
    this.failedReportCount = 0;
    this.sendAllIndex = 0;
    sessionStorage.setItem('reportProgress', 'true');

    const pdfData = this.dataSource.data;
    const zip = new JSZip();
    let temp = 0;
    let openedId = -1;
    const totalPdf = pdfData.length;
    // let pdfPromises = new Promise((resolve, reject) => resolve(1))
    let pdfPromises = pdfData.map((content, index) => {
      content.isDownloadAll = true;
      return new Promise<Blob>(async (resolve, reject) => {
        if (this.sendAllIndex < totalPdf) {
          const downloadPdf = pdfData[this.sendAllIndex];

          const intervalRef = setInterval(() => {
            if (this.isCancelled) {
              clearInterval(intervalRef);
              reject('Download process cancelled');
              return;
            }
            if (index == temp && openedId != index) {
              openedId++;
              const dialogRef = this.dialog.open(ReportComponent, {
                disableClose: false,
                hasBackdrop: true,
                height: '90vh',
                data: { element: content }
              });

              dialogRef.afterClosed().subscribe((pdfBlob: any) => {
                temp++;
                if (pdfBlob) {
                  zip.file(`${content.name}.pdf`, pdfBlob);
                  resolve(pdfBlob);
                  this.updateProgress(totalPdf);
                  this.successReportCount++;
                } else {
                  resolve(pdfBlob);
                  console.error('Failed to generate PDF for report:', content);
                  this.failedReportCount++;
                  this.updateProgress(totalPdf);
                }
                if (temp === totalPdf) {
                  clearInterval(intervalRef);
                }
              });
            }
            if (temp >= pdfData.length - 1) {
              clearInterval(intervalRef);
            }
          }, 100);
        } else {
          console.error('Failed to fetch report details for PDF:', content);
          this.failedReportCount++;
          this.updateProgress(totalPdf);
          reject('Failed to fetch report details');
        }
      });
    });
    Promise.all(pdfPromises)
      .then(() => {
        return zip.generateAsync({ type: 'blob' });
      })
      .then(content => {
        saveAs(content, 'game_report_esg.zip');
        this.showLoader();
        this.isProcessing = false;
        sessionStorage.removeItem('reportProgress');
        this.toster.success(`${this.successReportCount} report(s) downloaded successfully, ${this.failedReportCount} failed`);
      })
      .catch(error => {
        console.error('Error creating zip file:', error);
        this.isProcessing = false;
      });
  }

  cancel() {
    this.isCancelled = true
    this.isProcessing = false;
    this.progressValue = 0;
    this.successReportCount = 0;
    this.failedReportCount = 0;
    this.sendAllIndex = 0;

    this.dialog.closeAll();
    if (this.intervalRef) {
      clearInterval(this.intervalRef);
      this.intervalRef == null;
    }
    this.showLoader();
    this.toster.success('Download cancelled');
    // location.reload();
  }


  onImageChange(event: any) {
    let file: File = event.target.files[0];
    this.fileData = file
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.src = e.target.result;
    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }
  deletePlayer(player_id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: { heading: 'Are you sure to delete this player ?' }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.http.deletePlayerReport(player_id).subscribe((res: any) => {
          if (res['status']) {
            this.ngOnInit()
          } else {
            this.toster.error(res.message, '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
      }
    })
  }

  refreshPage(): void {
    this.ngOnInit()
  }

  downloadAllCSV() {
    const tableData = this.dataSource.data;
    console.log(tableData, ":: tableData");

    const header = 'Name , Email';
    const csvContent = tableData.map((element: any) => `${element.name},${element.email}`).join('\n');
    const csvData = `${header}\n${csvContent}`;

    this.downloadCsvFile(csvData);
  }

  downloadCsvFile(csvData: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'game_summary_report.csv';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}






@Component({
  selector: 'app-dialogueEditTemplate-game',
  templateUrl: './dialogue.edit.template.component.html',
  styleUrls: ['./game-report.component.css']
})
export class DialogueEditTemplate implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogueEditTemplate>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // @Inject(MAT_DIALOG_DATA) public results: any,
    public dialog: MatDialog,
    private toast: ToastrService,
    private http: HttpService
  ) { }

  ngOnInit(): void {
    this.gameId = this.data.element.id;
    this.gameType = this.data.element.game_type;
    if (this.gameType > 2) {
      this.gameName = this.data.element.game_name;

      if (this.data.results && this.data.results.length >= 1) {
        this.customName1 = this.data.results[0].name;
        this.customText1 = this.data.results[0].text.replaceAll('\n', '');

        if (this.data.results.length > 1) {
          this.customName2 = this.data.results[1].name;
          this.customText2 = this.data.results[1].text.replaceAll('\n', '');
        }
        if (this.data.results.length > 2) {
          this.customName3 = this.data.results[2].name;
          this.customText3 = this.data.results[2].text.replaceAll('\n', '');
        }
        if (this.data.results.length > 3) {
          this.customName4 = this.data.results[3].name;
          this.customText4 = this.data.results[3].text.replaceAll('\n', '');
        }
      }
      this.gameLinks = JSON.parse(this.data.results[0].link_text.replaceAll(/&quot;/ig, '"'))
      this.suggData = JSON.parse(this.data.results[0].sugg_name);
      this.suggData.map(async (res: any, index: any) => {
        if (index == 0) {
          this.expertDesc1 = res.desc
          this.expertName1 = res.name
          this.src1 = res.path
          const response = await fetch(this.src1);
          const blob = await response.blob();
          this.fileData1 = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        }
        else if (index == 1) {
          this.expertDesc2 = res.desc
          this.expertName2 = res.name
          this.src2 = res.path
          const response = await fetch(this.src2);
          const blob = await response.blob();
          this.fileData2 = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        }
        else {
          this.expertDesc3 = res.desc
          this.expertName3 = res.name
          this.src3 = res.path
          const response = await fetch(this.src3);
          const blob = await response.blob();
          this.fileData3 = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        }
      })
    } else {
      this.gameName = this.data.element.game_name
      this.esg = this.data.data[0].esg_score_desc.replaceAll('\n', '')
      this.effort = this.data.data[0].effort_point_desc.replaceAll('\n', '')
      this.brand = this.data.data[0].brand_value_desc.replaceAll('\n', '')
      this.gameLinks = JSON.parse(this.data.data[0].link_text.replaceAll(/&quot;/ig, '"'))
      this.suggData = JSON.parse(this.data.data[0].sugg_name)
      this.suggData.map(async (res: any, index: any) => {
        if (index == 0) {
          this.expertDesc1 = res.desc
          this.expertName1 = res.name
          this.src1 = res.path
          console.log(this.src1)
          const response = await fetch(this.src1);
          const blob = await response.blob();
          this.fileData1 = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        }
        else if (index == 1) {
          this.expertDesc2 = res.desc
          this.expertName2 = res.name
          this.src2 = res.path
          const response = await fetch(this.src2);
          const blob = await response.blob();
          this.fileData2 = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        }
        else {
          this.expertDesc3 = res.desc
          this.expertName3 = res.name
          this.src3 = res.path
          const response = await fetch(this.src3);
          const blob = await response.blob();
          this.fileData3 = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        }
      })
    }
  }
  src: any = ''
  fileData: any;
  status: any;
  esg: any;
  effort: any;
  brand: any;
  gameType: any;
  suggData: any;
  gameName: any;
  gameLinks: any = [];
  src1: any = "../../../../assets/img/account-icon.svg";
  src2: any = "../../../../assets/img/account-icon.svg";
  src3: any = "../../../../assets/img/account-icon.svg";
  fileData1: any;
  expertName1: any = '';
  expertDesc1: any = '';
  fileData2: any;
  expertName2: any = '';
  expertDesc2: any = '';
  fileData3: any;
  expertName3: any = '';
  expertDesc3: any = '';
  gameId: any;
  customText1: any;
  customText2: any;
  customText3: any;
  customText4: any;
  customName1: any;
  customName2: any;
  customName3: any;
  customName4: any;
  // univ_ids:any=['1001IMS','1002IMS','1003IMS'];
  // game_names:any=['ESG Marketing Game ESG','ESG Marketing Game ESG','ESG Marketing Game ESG','ESG Marketing Game ESG'];
  close() {
    this.dialogRef.close(false)
  }
  deleteLink(index: any) {
    this.gameLinks.splice(index, 1)
  }
  addLink() {
    this.gameLinks.push({ text: '', link: '' })
  }
  addData(event: any, from: any, index: any) {
    let data = event.target.value;
    if (from == 'text') {
      this.gameLinks[index].text = data
    }
    else {
      this.gameLinks[index].link = data
    }
  }
  generateFileData(imageUrl: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', imageUrl, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
          resolve(file);
        } else {
          reject('Failed to fetch the image.');
        }
      };

      xhr.onerror = () => {
        reject('Failed to fetch the image.');
      };

      xhr.send();
    });
  }

  save() {
    let sendData = [
      {
        name: this.expertName1,
        desc: this.expertDesc1
      },
      {
        name: this.expertName2,
        desc: this.expertDesc2
      },
      {
        name: this.expertName3,
        desc: this.expertDesc3
      }
    ];
    let data = new FormData();
    data.append('id', this.data.data[0].game_id)
    data.append('esg_score_desc', this.esg)
    data.append('effort_point_desc', this.effort)
    data.append('brand_value_desc', this.brand)
    data.append('gameLinks', JSON.stringify(this.gameLinks))
    data.append('expertData', JSON.stringify(sendData))
    data.append('profile1', this.fileData1)
    data.append('profile2', this.fileData2)
    data.append('profile3', this.fileData3)

    console.log(data)
    let flag = 1;
    this.gameLinks.map((res: any) => {
      if (res.text.length == 0 || res.link.length == 0)
        flag = 0
    })
    if (flag) {
      // if(this.src1!=null && this.src2!=null&&this.src3!=null&&this.expertName1.length>0
      //   &&this.expertName2.length>0&&this.expertName3.length>0&&this.expertDesc1.length>0&&this.expertDesc2.length>0
      //   &&this.expertDesc3.length>0){
      if (this.esg.length > 0 && this.effort.length > 0 && this.brand.length > 0) {
        this.http.saveGameReport(data).subscribe((res: any) => {
          if (res['status']) {
            this.dialogRef.close(true)
          }
          else {
            this.toast.warning("Oops! Something went wrong. Please try again")
          }
        })
      }
      else {
        this.toast.warning("Please enter the Details", '', { timeOut: 2000 })
      }
      // }
      // else{
      //   this.toast.warning("Please enter the Expert Details",'',{timeOut:2000})
      // }
    }
    else {
      this.toast.warning("Please enter the Resources", '', { timeOut: 2000 })
    }
  }

  update() {
    let sendData = [
      {
        name: this.expertName1,
        desc: this.expertDesc1
      },
      {
        name: this.expertName2,
        desc: this.expertDesc2
      },
      {
        name: this.expertName3,
        desc: this.expertDesc3
      }
    ];
    let data = new FormData();
    data.append('game_id', this.gameId)
    let formulas = [
      {
        formula_id: this.data.results[0].formula_id,
        game_id: this.gameId,
        text: this.customText1,
        name: this.customName1
      }
    ];
    if (this.data.results[1]) {
      formulas.push({
        formula_id: this.data.results[1].formula_id,
        game_id: this.gameId,
        text: this.customText2,
        name: this.customName2
      });
    }
    if (this.data.results[2]) {
      formulas.push({
        formula_id: this.data.results[2].formula_id,
        game_id: this.gameId,
        text: this.customText3,
        name: this.customName3
      });
    }
    if (this.data.results[3]) {
      formulas.push({
        formula_id: this.data.results[3].formula_id,
        game_id: this.gameId,
        text: this.customText4,
        name: this.customName4
      });
    }
    data.append('formulas', JSON.stringify(formulas));
    data.append('gameLinks', JSON.stringify(this.gameLinks))
    data.append('expertData', JSON.stringify(sendData))
    data.append('profile1', this.fileData1);
    data.append('profile2', this.fileData2);
    data.append('profile3', this.fileData3);

    console.log(data)
    let flag = 1;
    this.gameLinks.map((res: any) => {
      if (res.text.length == 0 || res.link.length == 0)
        flag = 0
    })
    if (flag) {
      if (this.customText1.length > 0) {
        this.http.updateCustomGameReport(data).subscribe((res: any) => {
          if (res['status']) {
            this.dialogRef.close(true)
          }
          else {
            this.toast.warning("Oops! Something went wrong. Please try again")
          }
        })
      }
      else {
        this.toast.warning("Please enter the Details", '', { timeOut: 2000 })
      }
    }
    else {
      this.toast.warning("Please enter the Resources", '', { timeOut: 2000 })
    }
  }

  setStatus(data: any) {
    this.status = data
  }
  onImageChange(event: any, from: any) {
    if (from == 1) {
      let file: File = event.target.files[0];
      this.fileData1 = file
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.src1 = e.target.result;
      };
      console.log(event, '11')
      const fileDom = document.getElementById('file_inp') as HTMLInputElement
      if (fileDom) {
        fileDom.value = ''
      }
    }
    else if (from == 2) {
      let file: File = event.target.files[0];
      this.fileData2 = file
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.src2 = e.target.result;
      };
      console.log(event, '22')
      const fileDom = document.getElementById('file_inp') as HTMLInputElement
      if (fileDom) {
        fileDom.value = ''
      }
    }
    else {
      let file: File = event.target.files[0];
      this.fileData3 = file
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.src3 = e.target.result;
      };
      console.log(event, '33')
      const fileDom = document.getElementById('file_inp') as HTMLInputElement
      if (fileDom) {
        fileDom.value = ''
      }
    }
  }
  viewReport() {
    const dialogRef = this.dialog.open(ReportComponent, {
      disableClose: false,
      hasBackdrop: true,
      height: "90vh",
      data: {
        report: this.data.data ? this.data.data[0] : this.data.results
      }
    })
  }
}



@Component({
  selector: 'app-dialogueRenameReport-game',
  templateUrl: './dialogue.rename-report.component.html',
  styleUrls: ['./game-report.component.css']
})
export class DialogueRenameReport implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogueEditTemplate>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private toast: ToastrService,
    private http: HttpService
  ) {

  }
  gameCode: any;
  reportName: any;
  ngOnInit(): void {
    console.log(this.data)
    this.gameCode = this.data.element.session_code
    this.reportName = this.data.element.report_name
  }

  close() {
    this.dialogRef.close()
  }
  save() {
    if (this.reportName.length == 0) {
      this.toast.warning('Please enter the Report Name', '', { timeOut: 2000 })
      return
    }
    let data = {
      name: this.reportName,
      session_id: this.data.element.session_id
    }
    this.http.renameReport(data).subscribe((res: any) => {
      if (res['status']) {
        this.toast.success('Report rename was successfull', '', { timeOut: 2000 })
        this.dialogRef.close(true)
      }
      else
        this.dialogRef.close(false)
    })
  }
}








@Component({
  selector: 'app-dialogueAddEmail-game',
  templateUrl: './dialogue.add-email.component.html',
  styleUrls: ['./game-report.component.css']
})
export class DialogueAddEmail implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogueEditTemplate>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private toast: ToastrService
  ) {

  }
  ngOnInit(): void {
  }
  studentEmail: any = '';

  close() {
    this.dialogRef.close(null)
  }
  save() {
    if (this.studentEmail.length == 0) {
      this.toast.warning("Please enter the email", '', { timeOut: 2000 })
      return
    }
    if (!this.studentEmail.match(emailRegex)) {
      this.toast.warning("Please enter a valid email", '', { timeOut: 2000 })
      return
    }
    this.dialogRef.close(this.studentEmail)
  }
}