<div class="dialogueConfigureGame">
    <div class="header">
        <div class="headerTxt">
            Edit Report Template
            
        </div>
        <div>
            <img style="cursor: pointer;margin-right: 2rem;" (click)="viewReport()" src="../../../../assets/img/carbon_view.svg"/>
            <img style="cursor: pointer;" (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
            </div>
    </div>
    <div class="body"  *ngIf="gameType === 1 || gameType ===2">
            <p class="note">Note :- Use YYYY for the Grades ( ie: Higher,Below,Equal) and XX for the Score ( ie: 56)
            </p>
            <div class="contents">
                <label>Game Name: <b>*</b></label>
                <input type="text" [(ngModel)]="gameName" >
            </div>
            <div class="contents">
                <label>ESG Score: <b>*</b></label>
                <textarea [(ngModel)]="esg"></textarea>
            </div>
            <div class="contents">
                <label>Effort Points: <b>*</b></label>
                <textarea [(ngModel)]="effort"></textarea>
            </div>
            <div class="contents">
                <label>Brand Value: <b>*</b></label>
                <textarea [(ngModel)]="brand"></textarea>
            </div>
            <label style=" color: #767676;margin-bottom: 10px;">Experts Panel:<b style="color: #E9675F;">*</b></label>
            <div class="expertsContainer">
                <div class="experts">
                    <div style="    display: flex;
                    position: relative;
                    max-width: min-content;    margin-bottom: 2rem;">
                        <img [src]="src1" alt="" style="    width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        border: 1px solid #B3C59E;">
        
                        <img src="../../../../assets/img/add_new_dp.svg" alt="" style="position: absolute;
                        
                        cursor: pointer;width: 30px;" (click)="fileInput_image1.click()">
                        <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event,1)"
                        #fileInput_image1 hidden id="file_inp" />
        
                    </div>
                    <input placeholder="Name" [(ngModel)]="expertName1"/>
                    <textarea placeholder="Comments" [(ngModel)]="expertDesc1"></textarea>
                </div>
                <div class="experts">
                    <div style="    display: flex;
                    position: relative;
                    max-width: min-content;    margin-bottom: 2rem;">
                        <img [src]="src2" alt="" style="    width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        border: 1px solid #B3C59E;">
        
                        <img src="../../../../assets/img/add_new_dp.svg" alt="" style="position: absolute;
                        
                        cursor: pointer;width: 30px;" (click)="fileInput_image2.click()">
                        <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event,2)"
                        #fileInput_image2 hidden id="file_inp" />
        
                    </div>                
                    <input placeholder="Name" [(ngModel)]="expertName2"/>
                    <textarea placeholder="Comments" [(ngModel)]="expertDesc2"></textarea>
                </div>
                <div class="experts">
                    <div style="    display: flex;
                    position: relative;
                    max-width: min-content;    margin-bottom: 2rem;">
                        <img [src]="src3" alt="" style="    width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        border: 1px solid #B3C59E;">
                        <img src="../../../../assets/img/add_new_dp.svg" alt="" style="position: absolute;
                        
                        cursor: pointer;width: 30px;" (click)="fileInput_image3.click()">
                        <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event,3)"
                        #fileInput_image3 hidden id="file_inp" />
                    </div>                
                    <input placeholder="Name" [(ngModel)]="expertName3"/>
                    <textarea placeholder="Comments" [(ngModel)]="expertDesc3"></textarea>
                </div>
            </div>
            <div class="gameLinks">
                <div class="resourceLinks">
                    <p>S.No</p>
                    <label>Resource Text</label>
                    <label>Resource Link</label>
                    <div class="addLink"><img (click)="addLink()" src="../../../../assets/img/addInstructions.svg"/></div>
                </div>
                <div class="resourceLinks" *ngFor="let data of gameLinks;let i=index">
                    <p style="margin-right: 10px; align-self: center;margin-bottom: 20px;color: #000;">{{i+1}})</p>
                    <input type="text" placeholder="Resource Text" value={{data.text}} (keyup)="addData($event,'text',i)" >
                    <input type="text" placeholder="Reference Link" value={{data.link}} (keyup)="addData($event,'link',i)" >
                    <img src="../../../../assets/img/mdi-light_delete.svg" (click)="deleteLink(i)" />
                </div>
            </div>
    </div>
    <div class="body" *ngIf="gameType > 2">
            <p class="note">Note :- Use YYYY for the Grades ( ie: Higher,Below,Equal) and XX for the Score ( ie: 56)
            </p>
            <div class="contents">
                <label>Game Name: <b>*</b></label>
                <input type="text" [(ngModel)]="gameName" >
            </div>

            <div *ngIf="customName1 !== null && customName1 !== undefined; else showFormula1">
                <div class="contents">
                    <label>{{customName1}}: <b>*</b></label>
                    <textarea [(ngModel)]="customText1"></textarea>
                </div>
            </div>
            <div *ngIf="customName2 !== null && customName2 !== undefined; else showFormula2">
                <div class="contents">
                    <label>{{customName2}}: <b>*</b></label>
                    <textarea [(ngModel)]="customText2"></textarea>
                </div>
            </div>
            <div *ngIf="customName3 !== null && customName3 !== undefined; else showFormula3">
                <div class="contents">
                    <label>{{customName3}}: <b>*</b></label>
                    <textarea [(ngModel)]="customText3"></textarea>
                </div>
            </div>  
            <div *ngIf="customText4 !== null && customName4 !== undefined;">
                <div class="contents">
                    <label>{{customName4}}: <b>*</b></label>
                    <textarea [(ngModel)]="customText4"></textarea>
                </div>
            </div>  
            <ng-template #showFormula1>
                <div class="contents">
                    <label>Formula 1: <b>*</b></label>
                    <textarea [(ngModel)]="customText1"></textarea>
                </div>
            </ng-template>                       
            <ng-template #showFormula2>
                <div class="contents" *ngIf="!customName1 && !customName2 && customName1 !== null">
                    <label>Formula 2: <b>*</b></label>
                    <textarea [(ngModel)]="customText2"></textarea>
                </div>
            </ng-template>         
            <ng-template #showFormula3>
                <div class="contents" *ngIf="!customName1 && !customName3 && customName1 !== null">
                    <label>Formula 3: <b>*</b></label>
                    <textarea [(ngModel)]="customText3"></textarea>
                </div>
            </ng-template>
            
            <label style=" color: #767676;margin-bottom: 10px;">Experts Panel:<b style="color: #E9675F;">*</b></label>
            <div class="expertsContainer">
                <div class="experts">
                    <div style="    display: flex;
                    position: relative;
                    max-width: min-content;    margin-bottom: 2rem;">
                        <img [src]="src1" alt="" style="    width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        border: 1px solid #B3C59E;">
        
                        <img src="../../../../assets/img/add_new_dp.svg" alt="" style="position: absolute;
                        
                        cursor: pointer;width: 30px;" (click)="fileInput_image1.click()">
                        <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event,1)"
                        #fileInput_image1 hidden id="file_inp" />
        
                    </div>
                    <input placeholder="Name" [(ngModel)]="expertName1"/>
                    <textarea placeholder="Comments" [(ngModel)]="expertDesc1"></textarea>
                </div>
                <div class="experts">
                    <div style="    display: flex;
                    position: relative;
                    max-width: min-content;    margin-bottom: 2rem;">
                        <img [src]="src2" alt="" style="    width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        border: 1px solid #B3C59E;">
        
                        <img src="../../../../assets/img/add_new_dp.svg" alt="" style="position: absolute;
                        
                        cursor: pointer;width: 30px;" (click)="fileInput_image2.click()">
                        <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event,2)"
                        #fileInput_image2 hidden id="file_inp" />
        
                    </div>                
                    <input placeholder="Name" [(ngModel)]="expertName2"/>
                    <textarea placeholder="Comments" [(ngModel)]="expertDesc2"></textarea>
                </div>
                <div class="experts">
                    <div style="    display: flex;
                    position: relative;
                    max-width: min-content;    margin-bottom: 2rem;">
                        <img [src]="src3" alt="" style="    width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        border: 1px solid #B3C59E;">
                        <img src="../../../../assets/img/add_new_dp.svg" alt="" style="position: absolute;
                        
                        cursor: pointer;width: 30px;" (click)="fileInput_image3.click()">
                        <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event,3)"
                        #fileInput_image3 hidden id="file_inp" />
                    </div>                
                    <input placeholder="Name" [(ngModel)]="expertName3"/>
                    <textarea placeholder="Comments" [(ngModel)]="expertDesc3"></textarea>
                </div>
            </div>
            <div class="gameLinks">
                <div class="resourceLinks">
                    <p>S.No</p>
                    <label>Resource Text</label>
                    <label>Resource Link</label>
                    <div class="addLink"><img (click)="addLink()" src="../../../../assets/img/addInstructions.svg"/></div>
                </div>
                <div class="resourceLinks" *ngFor="let data of gameLinks;let i=index">
                    <p style="margin-right: 10px; align-self: center;margin-bottom: 20px;color: #000;">{{i+1}})</p>
                    <input type="text" placeholder="Resource Text" value={{data.text}} (keyup)="addData($event,'text',i)" >
                    <input type="text" placeholder="Reference Link" value={{data.link}} (keyup)="addData($event,'link',i)" >
                    <img src="../../../../assets/img/mdi-light_delete.svg" (click)="deleteLink(i)" />
                </div>
            </div>
    </div>
    <div class="footer">
        <div class="cancel" (click)="close()">
            Cancel
        </div>
        <div class="save" *ngIf="gameType <= 2" (click)="save()">
            UPDATE
        </div>
        <div class="save" *ngIf="gameType > 2" (click)="update()">
            UPDATE
        </div>
    </div>
</div>