import { Component, EventEmitter, Inject, OnInit, Output, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteDialogueComponentComponent } from '../../commonComponants/delete-dialogue-component/delete-dialogue-component.component';
import { ResetPasswordComponent } from '../../commonComponants/reset-password/reset-password.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/https/http.service';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { DialogueEditTemplate } from '../game-report/game-report.component';
import { ChangeDetectorRef } from '@angular/core';
import { NgZone } from '@angular/core';
import * as math from 'mathjs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import * as d3 from 'd3';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.css']
})
export class GameListComponent implements OnInit {

  @Input() isNewGame = false;
  @Input() gameName = ''
  constructor(
    public dialog: MatDialog,
    private toast: ToastrService,
    private http: HttpService,
    private dataShare: DataShareService,
    private router: Router,
    private toster: ToastrService
  ) { }
  isAddGame: boolean = false
  ngOnInit(): void {
    this.userId = sessionStorage.getItem('user_id')
    if (!this.userId) {
      this.router.navigate(['/login']);
      this.toast.warning("Something went wrong. Please Login Again", '', { timeOut: 2000 })
    }
    if (this.router.url == '/superAdminDashboard') this.adminFlag = true
    this.getGameList(this.dataShare.gameID)
  }
  adminFlag: boolean = false
  editTooltip: string = "Edit game";
  copyTooltip: string = "Copy game";
  customGameName: string = ''
  userId: any;
  selectedFilter: any = '';
  displayedColumns: string[] = ['sl_no', 'game_name', 'game_code', 'edit', 'no_of_rounds', 'template', 'status', 'modify'];
  dataBM: any[] = []
  instructions: any;
  gameList: any = []
  dataSource: any = new MatTableDataSource();
  filterByStatus(data: any) {
    this.selectedFilter = data
    console.log(this.selectedFilter)
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  resetPassword() {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
      }
    })
  }
  editTemplate(element: any) {
    console.log(element)
    if(element.game_type > 2){
      this.http.getCustomGameReport(element.id).subscribe((res: any) => {
        if (res['status']) {
          const dialogRef = this.dialog.open(DialogueEditTemplate, {
            disableClose: true,
            hasBackdrop: true,
            data: {
              data: res['data'],
              results: res['results'],
              element
            }
          })
        } else {
          this.toast.warning(res['message'], '', { timeOut: 2000 })
        }
      }, (error: any) => {
        this.toast.warning('Add atleast formula', error.error['message'], { timeOut: 2000 })
      });
    } else {
      this.http.getGameReport(element.id).subscribe((res: any) => {
        if (res['status']) {
          const dialogRef = this.dialog.open(DialogueEditTemplate, {
            disableClose: true,
            hasBackdrop: true,
            data: {
              data: res['data'],
              element
            }
          })
        }
        else {
          this.toast.warning(res['message'], '', { timeOut: 2000 })
        }
      })
    }
  }
  confirmDelete() {
    const dialogRef = this.dialog.open(DeleteDialogueComponentComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        heading: "Are you sure you want to remove the Instructor?"
      }
    })
  }
  handleEditInstructions(id: any, name: any) {
    this.http.getInstructions(id).subscribe((res: any) => {
      if (res['status']) {
        if (res['data'].length > 0)
          this.instructions = res['data'];
        else
          this.instructions = []
        const dialogRef = this.dialog.open(DialogueAddInstructions, {
          disableClose: true,
          hasBackdrop: true,
          data: {
            gameName: name,
            data: this.instructions
          }
        }).afterClosed().subscribe((res: any) => {
          if (res.status) {
            console.log('after', res.data)
            this.instructions = res.data;
            if (id != null) {
              this.addInstructions(res.data, id)
            }
          }
        })
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  addInstructions(data: any, id: any) {
    this.http.addInstructions(data, id).subscribe((res: any) => {
      if (res['status']) {
        this.toast.success('Instructions saved successfully', '', { timeOut: 2000 });
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  addGame(type: any, element: any) {
    this.dataShare.addGame = type;
    this.dataShare.editGameData = element;
    this.isAddGame = !this.isAddGame
    this.getGameList(this.dataShare.gameID)
  }
  setStatus(status: any, element: any) {
    let data = {
      game_id: element.id,
      status: status
    }
    this.http.updateGameStatus(data).subscribe((res: any) => {
      if (res['status']) {
        this.toast.success('Game status updated successfully', '', { timeOut: 2000 });
        this.getGameList(this.dataShare.gameID)
      } else {
        this.toast.error('Oops! Something went wrong.', '', { timeOut: 2000 });
      }
    }, (err: any) => {
      if (err.status == 422) {
        this.toast.warning(err.error['message'], '', { timeOut: 2000 });
      } else {
        this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 });
      }

    })

  }
  getGameList(gameId: any) {
    this.http.getGameListESG(gameId).subscribe((res: any) => {
      this.dataSource.data = res.data;
      this.gameList = res.data;
    })
  }

  copyGame(element: any) {
    console.log(element);
    const dialogRef = this.dialog.open(DialogueCopyGame, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        game_id: element.id
      }
    })

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getGameList(this.dataShare.gameID);
      }
    })
  }
  deleteGame(game_id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: { heading: 'Are you sure to delete this game ?' }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.http.deleteGame(game_id).subscribe((res: any) => {
          if (res['status']) {
            this.ngOnInit()
          } else {
            this.toster.error(res.message, '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
      }
    })
  }
}


@Component({
  selector: 'app-copy-game',
  templateUrl: './dialogue.copy-game.component.html',
  styleUrls: ['./game-list.component.css']
})
export class DialogueCopyGame implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogueCopyGame>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private httpSv: HttpService,
  ) {

  }

  ngOnInit(): void {

  }

  gameName: any = '';
  gameCode: any = '';
  gameCodeRegex = /^([0-9A-Z]{8})$/

  onInputChange() {
    this.gameCode = this.gameCode.toUpperCase()
  }

  close() {
    this.dialogRef.close(false)
  }

  save() {
    let gameData = {
      game_id: this.data.game_id,
      game_name: this.gameName,
      game_code: this.gameCode
    }

    if (this.gameName != '') {
      if (this.gameCode != '') {
        if (this.gameCode.length == 8) {
          if (this.gameCodeRegex.test(this.gameCode)) {
            this.httpSv.addGameByCopy(gameData).subscribe((res: any) => {
              console.log(res)
              if (res['status']) {
                this.toast.success('Game created successfully', '', { timeOut: 2000 });
                this.dialogRef.close(true)
              } else {
                this.toast.warning('Oops! Something went wrong', '', { timeOut: 2000 });
              }
            }, (err) => {
              if (err.status == 422) {
                this.toast.warning(err.error['message'], '', { timeOut: 2000 })
              } else {
                this.toast.warning('Opps! Something wnet wrong', '', { timeOut: 2000 })
              }
            })
          } else {
            this.toast.warning('Only alphNumeric 8 characters allowed', '', { timeOut: 2000 });
          }
        } else {
          this.toast.warning('Please enter 8 characters for game code', '', { timeOut: 2000 });
        }
      } else {
        this.toast.warning('Please enter game code', '', { timeOut: 2000 });
      }
    } else {
      this.toast.warning('Please enter game name', '', { timeOut: 2000 });
    }
  }

}






@Component({
  selector: 'app-addModify-game',
  templateUrl: './add-modify-game.component.html',
  styleUrls: ['./game-list.component.css'],
})
export class AddModifyGame implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private dataShare: DataShareService,
    private http: HttpService,
    private toast: ToastrService
  ) {

  }
  ngOnInit(): void {
    if (this.dataShare.addGame == 'edit') {
      this.editData = this.dataShare.editGameData;
      this.gameId = this.editData.id
      this.gameName = this.editData.game_name;
      this.gameType = this.editData.game_type
      this.gameCode = this.editData.game_code;
      this.checked = this.editData.is_identifer_enabled;
      this.minEsgScore = this.editData.min_esg_score?.toString();
      this.maxEsgScore = this.editData.max_esg_score?.toString();
      this.minBrandValue = this.editData.min_brand_value?.toString();
      this.maxBrandValue = this.editData.max_brand_value?.toString();
      this.effortPoints = this.editData.effort_points?.toString();
      if (this.editData.game_type != 1 && this.editData.game_type != 2) {
        this.getGameLabel()
        this.getGameFormula()
        this.getGameRound(this.editData.id)
        this.getGameInstructions(this.editData.id)
      }
      else {
        this.getGameRound(this.editData.id)
        this.getGameInstructions(this.editData.id)
      }
    }
    this.dataSource.data = []
    this.addGame = this.dataShare.addGame;
    this.createdGameName = this.dataShare.gameName
    this.createdGameId = this.dataShare.gameID
  }
  addGame: any;
  createdGameName: any
  createdGameId: any
  gameType: any;
  editData: any = null;
  gameLables: any = []
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['round_no', 'theme_name', 'ques_linkage', 'modify'];
  isAddRound: boolean = false
  rounds: any = [];
  gameCode: any = '';
  gameName: any = '';
  gameId: any = null;
  instructions: any = null;
  minEsgScore: any = '';
  checked: any = false;
  maxEsgScore: any = '';
  minBrandValue: any = '';
  maxBrandValue: any = '';
  effortPoints: any = '';
  gameFormula: any = []
  // gameFormula:any = ''
  addRound(element: any) {
    if (this.gameId != null) {
      if (this.dataShare.gameID != 1 && this.dataShare.gameID != 2) {
        let curveBallCheck = 0
        this.gameFormula.map((data) => {
          if (data.is_curve_ball == 1)
            curveBallCheck = 1
        })
        if (curveBallCheck == 0) {
          this.toast.error('Is Effort points, Must be enabled for any one of the Formula', '', { timeOut: 2000 })
          return;
        }
        let labelCheck = 0
        let labelArray: any = []
        this.gameLables.map((label: any) => {
          labelArray.push(label.name)
        })
        let checkValue = ''
        const operators = /[-+/%*()]/;
        this.gameFormula.map((formula) => {
          let formulaData = formula.formula.split(operators)
          formulaData.map((data) => {
            console.log(labelArray, data)
            if (!labelArray.includes(data) && data != 'Option Value' && data.length > 0) {
              checkValue = data
              labelCheck = 1
            }
          })
        })
        if (labelCheck == 1) {
          this.toast.error('Please Enter a Valid Formula.' + checkValue + ' is not a valid label.', '', { timeOut: 2000 })
          return;
        }
      }
      this.dataShare.roundData = element;
      this.isAddRound = !this.isAddRound
      setTimeout(() => {
        this.getGameRound(this.editData.id);
      }, 700);
    }
    else {
      this.toast.warning('Please save the Game to continue', '', { timeOut: 2000 })
    }
  }

  dropTable(event: any) {
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    this.dataSource.data = [...this.dataSource.data]
    let data = {
      rounds: this.dataSource.data
    }
    this.http.reOrderRounds(data).subscribe((res: any) => {
    })
  }

  onInputChange(type: any) {
    if (type === 'gameCode') {
      this.gameCode = this.gameCode.toUpperCase().trim();
    }
  }
  preventText(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k >= 48 && k <= 57) || k == 43 || k == 45);
  }
  saveGame(from: any = null) {
    if (this.gameName.length == 0) {
      this.toast.warning('Please Enter Game Name', '', { timeOut: 2000 })
    }
    else if (this.gameCode.length == 0) {
      this.toast.warning('Please Enter Game Code', '', { timeOut: 2000 })
    }
    else {
      if (this.dataShare.gameID == 1) {
        // FOR ESG GAME SAVING
        if (this.minEsgScore != '') {
          if (this.maxEsgScore != '') {
            if (this.minBrandValue != '') {
              if (this.maxBrandValue != '') {
                if (this.effortPoints != '') {
                  let data = new FormData();
                  data.append('roundData', JSON.stringify(this.rounds))
                  data.append('instructions', this.instructions)
                  data.append('gameName', this.gameName)
                  data.append('gameCode', this.gameCode)
                  data.append('minEsgScore', this.minEsgScore.toString())
                  data.append('maxEsgScore', this.maxEsgScore.toString())
                  data.append('minBrandValue', this.minBrandValue.toString())
                  data.append('maxBrandValue', this.maxBrandValue.toString())
                  data.append('effortPoints', this.effortPoints.toString())
                  data.append('gameType', this.dataShare.gameID)
                  data.append('isIdentifierEnabled', this.checked ? '1' : '0')

                  if (this.gameId)
                    data.append('gameId', this.gameId)
                  this.http.addGame(data).subscribe((res: any) => {
                    if (res['status']) {
                      this.gameId = res['data'];
                      this.dataShare.editGameData.game_name = this.gameName;
                      this.dataShare.editGameData.game_code = this.gameCode;
                      console.log(this.dataShare.editGameData, 'qqqqqq')
                      this.toast.success(res['message'], '', { timeOut: 2000 })
                      if (from == 'ins')
                        this.handleAddInstructions()
                      if (from == 'round')
                        this.handleAddRounds()
                    }
                    else {
                      this.toast.error(res.message, '', { timeOut: 2000 })
                    }
                  }, (err) => {
                    if (err.status == 422) {
                      this.toast.warning(err.error['message'], '', { timeOut: 2000 })
                    } else {
                      this.toast.warning('Opps! Something went wrong', '', { timeOut: 2000 })
                    }
                  })
                } else {
                  this.toast.warning('Please enter Effort Points', '', { timeOut: 2000 })
                }
              } else {
                this.toast.warning('Please enter max Brand Value.', '', { timeOut: 2000 })
              }
            } else {
              this.toast.warning('Please enter min Brand Value.', '', { timeOut: 2000 })
            }
          } else {
            this.toast.warning('Please enter max ESG score.', '', { timeOut: 2000 })
          }
        } else {
          this.toast.warning('Please enter min ESG score.', '', { timeOut: 2000 })
        }
      }
      // CUSTOM ESG GAME
      else {
        this.gameLables.forEach((label) => {
          if (label.name.length == 0 || label.value.length == 0) {
            this.toast.warning('Please enter valid data for Game lables', '', { timeOut: 2000 })
            return;
          }
        })
        let data = new FormData();
        data.append('roundData', JSON.stringify(this.rounds))
        data.append('instructions', this.instructions)
        data.append('gameName', this.gameName)
        data.append('gameCode', this.gameCode)
        // data.append('gameLabel',JSON.stringify(this.gameLables))
        // data.append('gameFormula',JSON.stringify(this.gameFormula))
        data.append('gameType', this.dataShare.gameID)
        data.append('isIdentifierEnabled', this.checked ? '1' : '0')
        if (this.gameId)
          data.append('gameId', this.gameId)
        this.http.addGame(data).subscribe((res: any) => {
          if (res['status']) {
            // if(this.gameId!=null){
            this.dataShare.editGameData = {
              game_name: '',
              game_code: ''
            }
            this.dataShare.editGameData.game_name = this.gameName;
            this.dataShare.editGameData.game_code = this.gameCode;
            // }
            this.gameId = res['data'];
            this.dataShare.editGameData.game_name = this.gameName;
            this.dataShare.editGameData.game_code = this.gameCode;
            this.toast.success(res['message'], '', { timeOut: 2000 })
            if (from == 'ins')
              this.handleAddInstructions()
            if (from == 'round')
              this.handleAddRounds()
          }
          else {
            this.toast.error(res.message, '', { timeOut: 2000 })
          }
        }, (err) => {
          if (err.status == 422) {
            this.toast.warning(err.error['message'], '', { timeOut: 2000 })
          } else {
            this.toast.warning('Opps! Something went wrong', '', { timeOut: 2000 })
          }
        })
      }

    }

  }
  editFormula(data: any) {
    this.dialog.open(DialogueMakeFormula, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        label: this.gameLables,
        isEdit: true,
        data: data
      }
    }).afterClosed().subscribe((res) => {
      if (res['status']) {
        console.log("after clod", res['data'])
        this.editFormulaAPI(res['data'])
      }
    })
  }
  removeLabel(index: any) {
    this.gameLables.splice(index, 1)
  }
  handleAddGameLabel() {
    if (this.gameId == null)
      this.toast.error("Game has to be saved Before proceeding", '', { timeOut: 2000 })
    else {
      this.dialog.open(DialogueAddlabel, {
        disableClose: true,
        hasBackdrop: true,
        data: this.gameId
      }).afterClosed().subscribe((res) => {
        if (res) {
          this.getGameLabel()
        }
      })
    }
  }
  handleMakeFormula() {
    if (this.gameId == null)
      this.toast.error("Game has to be saved Before proceeding", '', { timeOut: 2000 })
    else {
      this.dialog.open(DialogueMakeFormula, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          label: this.gameLables,
          isEdit: false,
        }
      }).afterClosed().subscribe((res) => {
        if (res['status']) {
          this.addFormula(res['data'])
        }
      })
    }
  }
  getGameLabel() {
    this.http.getGameLabel(this.gameId).subscribe((res) => {
      if (res['status']) {
        this.gameLables = res['data']
      }
    })
  }
  deleteFormula(id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: { heading: 'Are you sure to delete this Score Name? The values assigned to the Questions will also be removed.' }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.http.deleteFormula(id).subscribe((res) => {
          if (res['status']) {
            this.getGameFormula()
          }
        })
      }
    })
  }
  deleteLabel(id: any) {
    this.http.deleteLabel(id).subscribe((res) => {
      if (res['status']) {
        this.getGameLabel()
      }
    })
  }
  addFormula(data) {
    let postData = {
      formula: data.formula.join(),
      label: data.label,
      isCurveBall: data.isCurveBall,
      value: data.value.toString()
    }
    this.http.addFormula(this.gameId, postData).subscribe((res) => {
      if (res['status']) {
        this.getGameFormula()
      }
    })
  }
  editFormulaAPI(data: any) {
    let postData = {
      formula: data.formula.join(),
      label: data.label,
      isCurveBall: data.isCurveBall,
      value: data.value.toString(),
      id: data.id
    }
    this.http.editFormula(this.gameId, postData).subscribe((res) => {
      if (res['status']) {
        this.getGameFormula()
      }
    })
  }
  getGameFormula() {
    this.http.getGameFormula(this.gameId).subscribe((res) => {
      if (res['status']) {
        let formulaData: any = []
        res['data'].map((formula) => {
          let data = {
            formula: formula.formula.replaceAll(",", ''),
            label: formula.name,
            id: formula.id,
            value: formula.value,
            is_curve_ball: formula.is_curve_ball
          }
          formulaData.push(data)
        })
        this.gameFormula = formulaData
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  goBackGame() {
    this.messageEmitter.emit('game')
  }
  getGameRound(id: any) {
    this.http.getGameRounds(id).subscribe((res: any) => {
      if (res['status']) {
        this.rounds = res.data;
        this.dataSource.data = res.data
        this.dataShare.roundList = res.data
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  getGameInstructions(id: any) {
    this.http.getInstructions(id).subscribe((res: any) => {
      if (res['status']) {
        if (res['data'].length > 0)
          this.instructions = res['data'];
        else { }
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  addRoundAPI(data: any, id: any) {
    this.http.addRound(data, id).subscribe((res: any) => {
      if (res['status']) {
        this.getGameRound(this.editData ? this.editData.id : id)
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }

  addInstructions(data: any, id: any) {
    this.http.addInstructions(data, id).subscribe((res: any) => {
      if (res['status']) {
        this.getGameInstructions(id);
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  handleAddInstructions() {
    if (this.gameId == null)
      this.toast.error("Game has to be saved Before proceeding", '', { timeOut: 2000 })
    else {
      const dialogRef = this.dialog.open(DialogueAddInstructions, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          gameName: this.gameName,
          data: this.instructions
        }
      }).afterClosed().subscribe((res: any) => {
        if (res.status) {
          console.log('after', res.data)
          this.instructions = res.data;
          if (this.gameId != null) {
            this.addInstructions(res.data, this.gameId)
          }
        }
        else {
          this.getGameInstructions(this.gameId);
        }
      })
    }
  }
  handleAddRounds() {
    if (this.gameId == null)
      this.toast.error("Game has to be saved Before proceeding", '', { timeOut: 2000 })
    else {
      const dialogRef = this.dialog.open(DialogueAddRound, {
        disableClose: true,
        hasBackdrop: true,
        data: {
        }
      }).afterClosed().subscribe((res: any) => {
        if (res.status) {
          this.dataSource.data = this.rounds
          this.dataShare.roundList = this.rounds
          this.rounds.push(res.data)
          if (this.gameId) {
            let data = new FormData();
            data.append('file', res.data.file)
            data.append('round_name', res.data.round_name)
            this.addRoundAPI(data, this.gameId)
          }
        }
      })
    }
  }
  handleDelete(id: any) {
    const dialogRef = this.dialog.open(DeleteDialogueComponentComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        heading: ' Are you sure you want to Delete this round ?'
      }
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        this.http.deleteRound(id).subscribe((res: any) => {
          if (res['status']) {
            this.getGameRound(this.gameId)
          }
          else {
            this.toast.error(res.message, '', { timeOut: 2000 })
          }
        })
      }
    })
  }
}







@Component({
  selector: 'app-addModify-round',
  templateUrl: './add-modify-round.component.html',
  styleUrls: ['./game-list.component.css']
})

export class AddModifyRound implements OnInit {
  @Output() messageEmitterRound = new EventEmitter<any>();
  constructor(
    public dialog: MatDialog,
    private dataShare: DataShareService,
    private http: HttpService,
    private toast: ToastrService

  ) {

  }
  rounds: any;
  questions: any;
  questionIdArr: any = [];
  questionArray: any = [];
  nextQuesIdArr: any = [];
  disabledNextQuesIdArr: any = [];
  gameName: any;
  hoverIndex: any = 0
  gameCode: any;
  isEditable = false
  editableIndex!: number;
  editData: any;
  curveBallQuestion: any = [];
  selectedRoundId: any;
  editableQuestion: any = {};
  isOnEdit: any = false;
  unsaved: any = true;
  saved: any = false;
  instructions: any;
  createdGameName: any;
  gameId: any;
  possibleFirstQuesId: number[] = [];
  linkedQuesRoutes: number[][] = [];
  customLables: any = []
  isCustomgame: boolean = false
  ngOnInit(): void {
    if (this.dataShare.gameID != 1) {
      this.isCustomgame = true
    }
    console.log("kskwskskks", this.dataShare)
    if (this.dataShare.addGame == 'edit') {
      this.editData = this.dataShare.editGameData;
      this.gameId = this.editData.id;
      this.gameName = this.editData.game_name;
      this.gameCode = this.editData.game_code;
      this.rounds = this.dataShare.roundList;
      this.selectedRoundId = this.dataShare.roundData.id;
      this.getGameRoundQuestions(this.dataShare.roundData.id)
      this.isOnEdit = true
      this.createdGameName = this.dataShare.gameName
    }
    else {
      this.editData = this.dataShare.editGameData;
      this.gameId = this.dataShare.roundData.game_id;
      this.gameName = this.editData.game_name;
      this.gameCode = this.editData.game_code;
      this.rounds = this.dataShare.roundList;
      this.selectedRoundId = this.dataShare.roundData.id;
      this.getGameRoundQuestions(this.dataShare.roundData.id)
      this.isOnEdit = true
      this.createdGameName = this.dataShare.gameName
      // this.gameId=this.dataShare.roundData.id;
      // this.gameName=this.dataShare.roundData.game_name;
    }
  }
  goBackRound() {
    let data = {
      gameId: this.gameId,
      roundId: this.selectedRoundId,
      firstQuesId: this.possibleFirstQuesId.length == 1 ? this.possibleFirstQuesId[0] : null
    }
    this.http.esgGameRoundLinkageValidation(data).subscribe((res: any) => {
      if (res['status']) {
      }
    })
    this.messageEmitterRound.emit()
  }
  changeIndex(index: any) {
    this.hoverIndex = index
  }

  disableEditableQues() {
    this.editableQuestion = {};
    this.isEditable = false;
  }
  updateQuestionCustom(event: any, label: any, labelIndex: any, optionIndex: any) {
    let data = event.target.value
    this.editableQuestion.labels[optionIndex][labelIndex].value = data
  }
  handleEdit(question: any, quesIndex: number) {
    if (this.isOnEdit && this.isEditable === false) {
      this.editableQuestion = question;
      this.isEditable = true;
      this.editableIndex = quesIndex;
      this.unsaved = true;
      this.saved = false
    } else if (this.isEditable === true) {
      this.toast.warning(`Please, first save the question number ${this.questionIdArr.indexOf(this.editableQuestion.id)}.`, '', { timeOut: 2000 })
    }

    const mergedArray = this.linkedQuesRoutes.reduce((result, currentArray) => {
      const indexOfTen = currentArray.indexOf(question.id);

      if (indexOfTen !== -1) {
        // Concatenate unique values up to the index of Question.id from the current array
        const valuesToAdd = currentArray.slice(0, indexOfTen + 1);
        result = Array.from(new Set(result.concat(valuesToAdd)));
      }
      return result;
    }, []);

    this.disabledNextQuesIdArr = mergedArray;
  }
  updateQuestion(event: any, from: any) {
    let data = event.target.value
    console.log(data, this.editableQuestion.options[0])
    switch (from) {
      case 'question':
        this.editableQuestion.ques_text = data;
        break;
      case 'option1':
        this.editableQuestion.options[0].option_text = data;
        break;
      case 'option2':
        this.editableQuestion.options[1].option_text = data;
        break;
      case 'option3':
        this.editableQuestion.options[2].option_text = data;
        break;
      case 'ep1':
        this.editableQuestion.options[0].budget = data;
        break;
      case 'ep2':
        this.editableQuestion.options[1].budget = data;
        break;
      case 'ep3':
        this.editableQuestion.options[2].budget = data;
        break;
      case 'esg1':
        this.editableQuestion.options[0].esg_score = data;
        break;
      case 'esg2':
        this.editableQuestion.options[1].esg_score = data;
        break;
      case 'esg3':
        this.editableQuestion.options[2].esg_score = data;
        break;
      case 'bv1':
        this.editableQuestion.options[0].brand_value = data;
        break;
      case 'bv2':
        this.editableQuestion.options[1].brand_value = data;
        break;
      case 'bv3':
        this.editableQuestion.options[2].brand_value = data;
        break;
      // case 'n1':
      //   this.editableQuestion.options[0].next_ques_id=data;
      //   break;
      // case 'n2':
      //   this.editableQuestion.options[1].next_ques_id=data;
      //   break;
      // case 'n3':
      //   this.editableQuestion.options[2].next_ques_id=data;
      //   break;
    }
  }
  updateNextQuesId(quesId: any, from: any) {
    switch (from) {
      case 'option1':
        this.editableQuestion.options[0].next_ques_id = quesId;
        break;
      case 'option2':
        this.editableQuestion.options[1].next_ques_id = quesId;
        break;
      case 'option3':
        this.editableQuestion.options[2].next_ques_id = quesId;
        break;
    }
  }
  clickQuestion(question) {
    if (question.id != this.editableQuestion.id)
      this.isEditable = false
    else
      this.isEditable = true
  }
  preventText(event: any) {
    var k = event.charCode;  // or event.keyCode; (Both can be used)
    return (k >= 48 && k <= 57) || k == 43 || k == 45;
  }
  addQuestion(id: any, data: any) {
    this.http.addQuestion(id, data).subscribe((res: any) => {
      if (res['status']) {
        this.getGameRoundQuestions(this.selectedRoundId)
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  addcurveBall(id: any, data: any) {
    this.http.addcurveBall(id, data).subscribe((res: any) => {
      if (res['status']) {
        if (data.quesId) {
          this.toast.success('Curve ball question updated successfully.', '', { timeOut: 2000 })
        } else {
          this.toast.success('Curve ball question added successfully.', '', { timeOut: 2000 })
        }
        this.getGameRoundQuestions(this.selectedRoundId);
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }

  savingQuestion() {
    console.log(this.editableQuestion)
    if (this.editableQuestion.labels) {
      var valuesCheck = 0
      this.editableQuestion.labels.map((lable) => {
        lable.map((data) => {
          if (data.value == null)
            valuesCheck = 1
        })
      })
      if (valuesCheck) {
        this.toast.error("Please enter every required details", '', { timeOut: 2000 })
        return
      }
    }
    this.isEditable = false
    this.unsaved = false
    this.saved = true
    this.http.editQuestion(this.editableQuestion.id, this.editableQuestion).subscribe((res: any) => {
      if (res['status']) {
        this.getGameRoundQuestions(this.selectedRoundId)
        this.toast.success('Edited Successfully', '', { timeOut: 2000 })
      }
      else {
        this.toast.error(res.message, '', { timeOut: 2000 })
      }

    })
  }
  handleDeleteQuestion(question: any) {
    const dialogRef = this.dialog.open(DeleteDialogueComponentComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        heading: ' Are you sure you want to Delete this Question? The Links Between the questions will de Removed.'
      }
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        let data = {
          questionId: question.id,
          gameId: question.game_id,
          roundId: question.round_id
        }
        this.http.deleteQuestion(data).subscribe((res: any) => {
          if (res['status']) {
            if (question.curve_ball == 1) {
              this.toast.success('Curve ball question deleted successfully.', '', { timeOut: 2000 });
            } else {
              this.toast.success('Question deleted successfully.', '', { timeOut: 2000 });
            }
            setTimeout(() => {
              this.getGameRoundQuestions(this.selectedRoundId)
            }, 200)
            // this.getGameRoundQuestions(this.dataShare.roundData.id)
          }
        }, (err: any) => {
          this.toast.success('Oops! Something went wrong.', '', { timeOut: 2000 })
        })
      }
    })
  }
  getGameRoundQuestions(id: any) {
    let data = {
      overview: false,
      session_id: null,
      game_id: null,
      instructor_id: null
    }
    // console.log("getgma ewuateions")
    this.selectedRoundId = id;
    this.http.getRoundsQuestions(id, data).subscribe((res: any) => {
      if (res['status']) {
        let index;
        let data = res['data'];
        this.questionIdArr = [null];
        this.questionArray = ['']
        this.curveBallQuestion = [];
        this.editableQuestion = {};
        this.nextQuesIdArr = [];
        data.forEach((element: any, index: any) => {
          if (element.curve_ball == 1) {
            index = index;
            this.curveBallQuestion.push(element);
          } else {
            this.questionIdArr.push(element.id);
            this.questionArray.push(element.ques_text);
          }
        });
        data.forEach((element: any, index: any) => {
          if (element.curve_ball == 0) {
            this.nextQuesIdArr.push([]);
            element.options.forEach(element => {
              this.nextQuesIdArr[this.nextQuesIdArr.length - 1].push(this.questionIdArr.indexOf(element.next_ques_id));
            });
          }
        });
        let quesData: any = data.filter((element: any) => element.curve_ball != 1)
        this.questions = quesData
        console.log(this.questions[0].labels)
        this.quesLinkage(quesData)
        if (this.isCustomgame) {
          this.customLables = res['data'][0].labels
        }
        this.selectedRoundId
        // if(this.isOnEdit)
        //   this.editableQuestion=this.questions[0]
      }
      else {
        if (this.isCustomgame) {
          this.customLables = res['lables'][0].labels
          this.questions = []
        }
      }
    })
  }

  quesLinkage(data: any) {
    type Option = {
      optId: number;
      next_ques_id: number | null;
    };

    type Question = {
      id: number;
      curve_ball: number;
      options?: Option[];
    };

    function generateRoutes(data: Question[], currentId: number, currentRoute: number[], result: number[][]) {
      const currentQuestion = data.find(q => q.id === currentId);

      if (!currentQuestion) {
        // Invalid question ID
        return;
      }

      currentRoute.push(currentId);

      if (currentQuestion.options && currentQuestion.options.length > 0) {
        for (const option of currentQuestion.options) {
          if (option.next_ques_id !== null) {
            generateRoutes(data, option.next_ques_id, [...currentRoute], result);
          } else {
            result.push([...currentRoute]);
          }
        }
      } else {
        // No options, route ends
        result.push([...currentRoute]);
      }
    }

    function getAllRoutes(data: Question[]): number[][] {
      const result: number[][] = [];
      for (const question of data) {
        generateRoutes(data, question.id, [], result);
      }
      return result;
    }

    const allQuestionIds = data.map(item => item.id);

    // Extract all next_ques_ids from options
    const allNextQuesIds = data.reduce((acc, item) => {
      const optionNextQuesIds = (item.options || []).map(option => option.next_ques_id);
      return acc.concat(optionNextQuesIds.filter(id => id !== null)) as number[];
    }, []);

    // Find question IDs that are not linked to any next_ques_id
    this.possibleFirstQuesId = allQuestionIds.filter(id => !allNextQuesIds.includes(id));

    const routes = getAllRoutes(data);

    let filteredPaths: number[][] = [];
    routes.forEach((route) => {
      this.possibleFirstQuesId.forEach(function (quesId) {
        if (route[0] === quesId) {
          filteredPaths.push(route);
        }
      });
    });
    // console.log(this.possibleFirstQuesId)
    // console.log(filteredPaths)

    // const uniqueArrays = Array.from(new Set(filteredPaths.map(JSON.stringify)), JSON.parse);
    this.linkedQuesRoutes = Array.from(
      new Set(filteredPaths.map((entry: any) => JSON.stringify(entry))),
      (entry: string) => JSON.parse(entry) as number[]);

    // console.log(this.linkedQuesRoutes)
  }


  handleShowTree() {
    const dialogRef = this.dialog.open(DialogueShowTree, {
      disableClose: false,
      hasBackdrop: true,
      data: {
        id: this.selectedRoundId
      }
    })
  }
  handleAddQuestion(type: any, field: any) {
    const dialogRef = this.dialog.open(DialogueAddModifyQuestion, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        type: type,
        field: field,
        values: this.curveBallQuestion,
        themeName: this.rounds.filter((data: any) => data.id == this.selectedRoundId),
        isCustomgame: this.isCustomgame,
        customLables: this.customLables
      }
    }).afterClosed().subscribe((res: any) => {
      console.log(res, "aaaaaa")
      if (res.status) {
        if (!res.curveBall) {
          if (this.gameId)
            this.addQuestion(this.gameId, res.data);
        }
        else {
          this.addcurveBall(this.gameId, res.data);
        }
        // this.getGameRoundQuestions(this.gameId)
      }
    })
  }
  handleModifyInstructions() {
    const dialogRef = this.dialog.open(DialogueAddInstructions, {
      disableClose: true,
      hasBackdrop: true,
      data: {
      }
    })
  }
}




@Component({
  selector: 'app-dialogueAddInstructions-game',
  templateUrl: './dialogue.add-instructions.component.html',
  styleUrls: ['./game-list.component.css']
})
export class DialogueAddInstructions implements OnInit {

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<DialogueAddInstructions>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService
  ) {

  }
  ngOnInit(): void {
    console.log(this.data)
    if (this.data.data.length > 0) {
      this.pages = this.data.data
    }
  }
  pages: any[] = [
    {
      heading_text: '',
      title: '',
      desc_text: ''
    },
    {
      heading_text: '',
      title: '',
      desc_text: ''
    },
    {
      heading_text: '',
      title: '',
      desc_text: ''
    },
  ]
  close() {
    let data = {
      status: false,
      data: this.pages
    }
    this.dialogRef.close(data)
  }
  save() {
    var flag = 0;
    this.pages.map((res: any) => {
      if (res.heading_text.length == 0 || res.title.length == 0 || res.desc_text.length == 0)
        flag = 1;
    })
    if (flag == 1) {
      this.toast.error("Please fill the Details", '', { timeOut: 2000 })
      return;
    }
    let data = {
      status: true,
      data: this.pages
    }
    console.log("popo", data)
    this.dialogRef.close(data)
  }
  addPage() {
    if (this.pages.length < 5) {
      this.pages.push({
        header_text: '',
        title: '',
        desc_text: ''
      })
      setTimeout(() => {
        const container = this.scrollContainer.nativeElement;
        container.scrollTop = container.scrollHeight;
      }, 200)
    }
    else
      this.toast.warning('The Maximum pages limit is 5', '', { timeOut: 2000 })

  }
  handleDataEnter(event: any, from: any, index: any) {
    let data = event.target.value
    switch (from) {
      case 'header_text':
        this.pages[index].heading_text = data;
        break;
      case 'title':
        this.pages[index].title = data;
        break;
      case 'desc_text':
        this.pages[index].desc_text = data;
        break;
    }
  }
  deletePage(index: any) {
    if (this.pages.length > 3)
      this.pages.splice(index, 1)
    else
      this.toast.warning('The minimum required pages is 3', '', { timeOut: 2000 })
  }
}











@Component({
  selector: 'app-dialogueAddRound-game',
  templateUrl: './dialogue.addRound.component.html',
  styleUrls: ['./game-list.component.css']
})
export class DialogueAddRound implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogueAddRound>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService
  ) {

  }
  ngOnInit(): void {

  }
  fileData: any = null;
  src: any = '';
  themeName: any = '';
  onImageChange(event: any) {
    let file: File = event.target.files[0];
    this.fileData = file
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.src = e.target.result;
    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }
  changeContrast(e: any) {
    let container = document.getElementById('UploadImage');
    let val = e.value;
    container?.setAttribute("style", "filter: brightness(" + val + "%);");
  }
  close() {
    let data = {
      status: false
    }
    this.dialogRef.close(data)
  }
  save() {
    if (this.themeName.length == 0) {
      this.toast.warning('Please enter the theme name', '', { timeOut: 2000 });
      return;
    }
    if (this.fileData == null) {
      this.toast.warning('Please upload the image', '', { timeOut: 2000 })
      return;
    }
    let data = {
      status: true,
      data: {
        round_name: this.themeName,
        file: this.fileData
      }
    }
    this.dialogRef.close(data)
  }
}















@Component({
  selector: 'app-dialogueAddQuestion-game',
  templateUrl: './dialogue.addModifyQuestion.component.html',
  styleUrls: ['./game-list.component.css']
})
export class DialogueAddModifyQuestion implements OnInit {
  themeName: any;
  questionTxt: any = '';
  budget1: any = '';
  esg1: any = '';
  brandValue1: any = '';
  option1: any = '';
  budget2: any = '';
  esg2: any = '';
  brandValue2: any = '';
  option2: any = '';
  budget3: any = '';
  esg3: any = '';
  brandValue3: any = '';
  option3: any = '';
  question1: any = '';
  curvePopup1: any = '';
  curvePopup2: any = '';
  curveOption1: any = '';
  questionCB: any = '';
  curveOption2: any = '';
  curveBallId: any = '';
  next1: any;
  next2: any;
  next3: any;
  isCustomgame: boolean = false
  customLabels: any = []
  customLabelsValuesOption1: any = []
  customLabelsValuesOption2: any = []
  customLabelsValuesOption3: any = []
  label: any = []
  curveBallCustomLabel: any = ''
  formulaId: any = 0
  constructor(
    public dialogRef: MatDialogRef<DialogueAddModifyQuestion>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService
  ) {

  }
  ngOnInit(): void {
    console.log(this.data)
    this.themeName = this.data.themeName[0].round_name
    this.isCustomgame = this.data.isCustomgame
    this.customLabels = this.data.customLables
    this.customLabels[0].map((label) => {
      console.log(label)
      if (label.is_curve_ball == 1) {
        this.curveBallCustomLabel = label.name
        this.formulaId = label.formula_id
      }
    })
    console.log(this.curveBallCustomLabel)
    if (this.data.type == 'curveball' && this.data.field == 'edit') {
      var data = this.data.values[0];
      this.curveBallId = data.id;
      this.questionCB = data.ques_text;
      this.curveOption1 = data.options[0].option_text;
      this.budget1 = data.options[0]?.budget;
      this.budget2 = data.options[1]?.option_text !== '' ? data.options[1]?.budget : '';
      this.curveOption2 = data.options[1]?.option_text;
      this.curvePopup1 = data.options[0]?.popup;
      this.curvePopup2 = data.options[1]?.popup;
    }

    if (this.isCustomgame) {
      this.customLabels.forEach((label) => {
        let tempLabel: any = []
        label.forEach((data) => {
          let dataa = {
            name: data.name,
            value: 0,
            id: data.formula_id ? data.formula_id : data.id
          }
          tempLabel.push(dataa)
        })
        this.label.push(tempLabel)
      })
      console.log(this.label)
      this.customLabelsValuesOption1.push(...structuredClone(this.label[0]))
      this.customLabelsValuesOption2.push(...structuredClone(this.label[1]))
      this.customLabelsValuesOption3.push(...structuredClone(this.label[2]))
    }
  }
  close() {
    let data = {
      status: false
    }
    this.dialogRef.close(data)
  }
  preventText(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k >= 48 && k <= 57) || k == 43 || k == 45);
  }
  save() {
    let question;
    // if(this.data.type=='curveball'){
    //   if(this.questionCB.length>0 && this.curveOption1.length >0 && this.curvePopup1.length>0 &&
    //     this.budget1 ){}
    //     else{
    //       this.toast.error("Please enter atleast one Option",'',{timeOut:2000})
    //       return;
    //     }
    // }
    if (this.data.type == 'curveball' && this.data.field == 'edit') {
      question = {
        quesId: this.curveBallId,
        questionText: this.questionCB,
        themeName: this.themeName,
        roundId: this.data.themeName[0].id,
        isCustomGame: this.isCustomgame ? 1 : 0,
        formulaId: this.formulaId,
        curveBall: 1,
        options: [
          {
            id: this.data.values[0].options[0]?.id,
            option: this.curveOption1,
            popup: this.curvePopup1,
            budget: this.budget1
          },
          {
            id: this.data.values[0].options[1]?.id,
            option: this.curveOption2,
            popup: this.curvePopup2,
            budget: this.budget2

          }
        ]
      }
    }
    else if (this.data.type == 'curveball' && this.data.field == 'new') {
      question = {
        questionText: this.questionCB,
        themeName: this.themeName,
        isCustomGame: this.isCustomgame ? 1 : 0,
        roundId: this.data.themeName[0].id,
        curveBall: 1,
        formulaId: this.formulaId,
        options: [
          {
            budget: this.budget1,
            option: this.curveOption1,
            popup: this.curvePopup1
          },
          {
            budget: this.budget2,
            option: this.curveOption2,
            popup: this.curvePopup2
          }
        ]
      }
    }
    else {
      question = {
        questionText: this.questionTxt,
        themeName: this.themeName,
        roundId: this.data.themeName[0].id,
        curveBall: 0,
        options: [
          {
            option_text: this.option1,
            esg: this.esg1,
            effort: this.budget1,
            brandValue: this.brandValue1,
            nextQuestion: this.next1
          },
          {
            option_text: this.option2,
            esg: this.esg2,
            effort: this.budget2,
            brandValue: this.brandValue2,
            nextQuestion: this.next2
          },
          {
            option_text: this.option3,
            esg: this.esg3,
            effort: this.budget3,
            brandValue: this.brandValue3,
            nextQuestion: this.next3
          }
        ]
      }
    }
    let data = {
      status: true,
      data: question,
      curveBall: this.data.type == 'curveball' ? 1 : 0
    }

    if (this.data.type == 'curveball') {
      if (question.questionText !== '') {
        let emptyFieldOption = [1, 1];
        let i = 0
        for (let option of question.options) {
          i += 1;
          if (
            (i == 1 && option.budget.toString() == '') ||
            (i == 2 && option.budget.toString() == '' && (option.option !== '' || !(option.popup == '' || option.popup == null)))
          ) {
            this.toast.warning(`Please, enter option ${i}\'s effort point value.`, '', { timeOut: 3500 })
            break;
          } else {
            if (
              (i == 1 && option.option == '' && this.curveOption2 !== '') ||
              (i == 2 && option.option == '' && ((option.budget.toString() !== '' || !(option.popup == '' || option.popup == null))))
            ) {
              this.toast.warning(`Please, enter option ${i}\'s text.`, '', { timeOut: 3500 })
              break;
            } else {
              if (
                (i == 1 && (option.popup == '' || option.popup == null)) ||
                (i == 2 && (option.popup == '' || option.popup == null) && (option.option !== '' || option.budget.toString() !== ''))
              ) {
                this.toast.warning(`Please, enter option ${i}\'s popup text.`, '', { timeOut: 3500 })
                break;
              } else {
                emptyFieldOption[i - 1] = 0;
              }
            }
          }
        }

        if (!(emptyFieldOption.includes(1))) {
          this.dialogRef.close(data);
        }

      } else {
        this.toast.warning('Please, enter curve ball question\'s text.', '', { timeOut: 3500 })
      }

    } else {
      // FOR ESG GAME
      if (!this.isCustomgame) {
        if (!(question.questionText == undefined || question.questionText == '')) {
          let emptyFieldOption = [1, 1, 1];
          let i = 0
          for (let option of question.options) {
            i += 1;
            if (option.effort !== '') {
              if (option.esg !== '') {
                if (option.brandValue !== '') {
                  if (option.option_text !== '') {
                    emptyFieldOption[i - 1] = 0;
                  } else {
                    this.toast.warning(`Please, enter option ${i}\'s text.`, '', { timeOut: 3500 })
                    break;
                  }
                } else {
                  this.toast.warning(`Please, enter option ${i}\'s brand value point.`, '', { timeOut: 3500 })
                  break;
                }
              } else {
                this.toast.warning(`Please, enter option ${i}\'s esg point.`, '', { timeOut: 3500 })
                break;
              }
            } else {
              this.toast.warning(`Please, enter option ${i}\'s effort point.`, '', { timeOut: 3500 })
              break;
            }
          }

          if (!(emptyFieldOption.includes(1))) {
            this.dialogRef.close(data)
          }
        } else {
          this.toast.warning('Please, enter question\'s text.', '', { timeOut: 3500 })
        }
      }
      // FOR CUSTOM ESG GAME
      else {
        if (!(question.questionText == undefined || question.questionText == '')) {
          let labelCheck = 0
          let optionCheck = 0
          this.customLabelsValuesOption1.forEach((value) => {
            if (value.value?.length < 0) {
              labelCheck = 1
            }
          })
          this.customLabelsValuesOption2.forEach((value) => {
            if (value.value?.length < 0) {
              labelCheck = 1
            }
          })
          this.customLabelsValuesOption3.forEach((value) => {
            if (value.value?.length < 0) {
              labelCheck = 1
            }
          })
          if (labelCheck) {
            this.toast.warning('Please, enter the required fileds.', '', { timeOut: 3500 })
            return;
          }

          if (this.option1.length == 0 || this.option2.length == 0 || this.option3.length == 0) {
            optionCheck = 1
          }
          if (optionCheck) {
            this.toast.warning('Please, enter every Option fileds.', '', { timeOut: 3500 })
            return;
          }
          let optionvalues: any = []
          optionvalues.push(this.customLabelsValuesOption1)
          optionvalues.push(this.customLabelsValuesOption2)
          optionvalues.push(this.customLabelsValuesOption3)
          question = {
            questionText: this.questionTxt,
            themeName: this.themeName,
            roundId: this.data.themeName[0].id,
            optionValues: optionvalues,
            curveBall: 0,
            options: [
              {
                option_text: this.option1,
                esg: this.esg1,
                effort: this.budget1,
                brandValue: this.brandValue1,
                nextQuestion: this.next1
              },
              {
                option_text: this.option2,
                esg: this.esg2,
                effort: this.budget2,
                brandValue: this.brandValue2,
                nextQuestion: this.next2
              },
              {
                option_text: this.option3,
                esg: this.esg3,
                effort: this.budget3,
                brandValue: this.brandValue3,
                nextQuestion: this.next3
              }
            ]
          }
          let data = {
            status: true,
            data: question,
            curveBall: this.data.type == 'curveball' ? 1 : 0,
            gameType: this.isCustomgame
          }
          this.dialogRef.close(data)
        } else {
          this.toast.warning('Please, enter question\'s text.', '', { timeOut: 3500 })
        }
      }
    }
  }
}








@Component({
  selector: 'app-dialogueShowTree-game',
  templateUrl: './dialogue.show-tree.component.html',
  styleUrls: ['./game-list.component.css']
})
export class DialogueShowTree implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogueAddModifyQuestion>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private http: HttpService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {

  }
  ngOnInit(): void {
    this.getData()
  }
  questions: any = []
  startId: any = 0;
  curve = 'Basis';

  quizContainer: any;
  svgContainer: any;
  nodes: any[] = []
  links: any[] = []

  async getData() {
    let data = {
      overview: false,
      session_id: null,
      game_id: null,
      instructor_id: null
    }
    await this.http.getRoundsQuestions(this.data.id, data).subscribe((res: any) => {
      if (res['status']) {
        let data: any = [];
        const tempData = res['data'].filter((res: any) => res.curve_ball == 0);
        let tempQuesIds: any = [];
        tempData.forEach((question) => {
          tempQuesIds.push(question.id);
        })
        tempData.forEach((question, index) => {
          let tempOptions: any = []
          question.options.forEach((option, index) => {
            option.id = index + 1;
            option.next_ques_id = tempQuesIds.indexOf(option.next_ques_id) + 1;
            tempOptions.push(option);
          })
          question.id = index + 1;
          data.push(question);
        })
        this.drawQuizDiagram(data);
        this.cdr.detectChanges();

      }
    })
  }

  drawQuizDiagram(quizData: any) {
    // Create a flat array of nodes
    var dnodes: any = []
    var dlinks: any = []
    var id: any = 0;
    let questionData = quizData;
    questionData.forEach((question, index) => {
      if (question.curve_ball == 0) {
        if (id == 0)
          id = question.id
        dnodes.push({ label: question.ques_text, id: index + 1 });
        question.options.map((option: any) => {
          if (option.next_ques_id) {
            var demo = option.next_ques_id;
            var entered = 0;
            // TO CREATE A ANOTHER NODE IF THE NODE IS ALREADY PRESENT TO MAKE MULTIPLE NODES OF SAME VALUE
            dlinks.map((link: any) => {
              let flag = 1;
              if (link.target == demo) {
                entered = 1
                demo = option.next_ques_id + '_' + flag;
                while (this.checkExistence(dlinks, demo)) {
                  flag += 1;
                  demo = option.next_ques_id + '_' + flag;
                }
              }
            })
            dlinks.push({ source: question.id, target: demo })
            if (entered) {
              var qText = '';
              quizData.map((q: any) => {
                if (q.id == option.next_ques_id)
                  qText = q.ques_text
              })
              dnodes.push({ label: qText, id: demo });
            }

          }
        })
        this.cdr.detectChanges();
      }
    });
    this.cdr.detectChanges();
    this.zone.run(() => {
      this.links = dlinks
      this.nodes = dnodes
      this.startId = id

    });
    console.log(dnodes)
    console.log(dlinks)

  }
  getIdValue(id: any) {
    if (typeof (id) == 'number')
      return id
    else {
      var flag = id.split("_")
      flag = (parseInt(flag) - this.startId) + 1
      return flag
    }
  }
  checkExistence(links: any, element: any) {
    var flag = 0
    links.map((link: any) => {
      if (link.target == element)
        flag = 1
    })
    if (flag)
      return true
    return false
  }
  ngAfterViewInit() {


  }
}








@Component({
  selector: 'app-add-label',
  templateUrl: './dialogue.add-gamelabel.component.html',
  styleUrls: ['./game-list.component.css']
})
export class DialogueAddlabel implements OnInit {

  gameLabel: any = ''
  gameValue: any = ''
  constructor(
    public dialogRef: MatDialogRef<DialogueCopyGame>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private httpSv: HttpService,
    private datashare: DataShareService
  ) {

  }
  ngOnInit(): void {

  }
  close() {
    this.dialogRef.close(false)
  }
  create() {
    if (this.gameLabel.length == 0 || this.gameValue.length == 0) {
      this.toast.error("Please fill the required Details", '', { timeOut: 2000 })
      return
    }
    let data = {
      name: this.gameLabel,
      value: this.gameValue
    }
    this.httpSv.addGameLabel(data, this.data).subscribe((res) => {
      if (res['status']) {
        this.dialogRef.close(true)
      }
    }, (err: Error) => {
      this.toast.error("Something went wrong please try again", '', { timeOut: 2000 })
    })
  }

}





@Component({
  selector: 'app-add-label',
  templateUrl: './dialogue.makeformula.component.html',
  styleUrls: ['./game-list.component.css']
})
export class DialogueMakeFormula implements OnInit {

  gameLabelss: any = []
  operators: any = ['-', '+', "/", "*", "(", ")"]
  formula: any = []
  showFormula = ''
  labelName: any = ''
  labelValue: any = ''
  isCurveBall: any = "0"
  isEdit: any = false
  constructor(
    public dialogRef: MatDialogRef<DialogueCopyGame>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private httpSv: HttpService,
    private datashare: DataShareService
  ) {

  }
  ngOnInit(): void {
    this.gameLabelss = JSON.parse(JSON.stringify(this.data.label))
    this.gameLabelss.push({ id: 0, name: 'Option Value' })
    this.isEdit = this.data.isEdit
    if (this.data.isEdit) {
      this.labelName = this.data.data.label
      this.labelValue = this.data.data.value
      this.showFormula = this.data.data.formula.split(',').join()
      this.isCurveBall = this.data.data.is_curve_ball.toString()
      const operators = /([-+*/%()])/g;
      this.formula = this.data.data.formula.split(operators).filter(item => item !== "");
    }
  }
  makeFormula(data: any, from: any) {
    if (from) {
      this.formula.push(data.name);
    }
    else
      this.formula.push(data)
    this.showFormula = this.formula.join('')
  }
  delete() {
    this.formula.pop()
    this.showFormula = this.formula.join('')
  }

  validateFormulaSyntax(formula, gameLabelss) {
    function tokenize(formula, gameLabelss) {

      const labelNames = gameLabelss.map(label => label.name).join('|');
      const regex = new RegExp(`(${labelNames})|([+\\-*/%()])`, 'g');
      const tokens = formula.match(regex);

      return tokens ? tokens : [];
    }

    function validateTokens(tokens) {
      let openParentheses = 0;
      let lastToken = '';

      for (let token of tokens) {
        if (token === '(') {
          openParentheses++;
          if (lastToken && lastToken.match(/[a-zA-Z0-9\s]/)) return false;
        } else if (token === ')') {
          if (openParentheses === 0 || lastToken.match(/[\+\-\*\/\%\(]/)) return false;
          openParentheses--;
        } else if (token.match(/[a-zA-Z0-9\s]/)) {
          if (lastToken.match(/[a-zA-Z0-9\s]/) || lastToken === ')') return false;
        } else if (token.match(/[\+\-\*\%\/]/)) {
          if (!lastToken || lastToken.match(/[\+\-\*\/\%\(]/)) return false;
        } else {
          return false;
        }
        lastToken = token;
      }
      return openParentheses === 0 && !lastToken.match(/[\+\-\*\%\/]/);
    }
    const tokens = tokenize(formula, gameLabelss);
    console.log(tokens)
    return validateTokens(tokens);
  }

  close() {
    console.log("clise");
    this.dialogRef.close({ status: false, data: this.formula })
  }
  create() {
    if (!this.formula.includes('Option Value')) {
      this.toast.error("Please include OPTION VALUE", '', { timeOut: 2000 })
      return
    }
    if (this.labelName.length == 0) {
      this.toast.error("Please enter Label Name", '', { timeOut: 2000 })
      return
    }
    if (this.labelValue.length == 0) {
      this.toast.error("Please enter Label Value", '', { timeOut: 2000 })
      return
    }
    if (this.validateFormulaSyntax(this.showFormula, this.gameLabelss)) {
      if (this.isEdit)
        this.dialogRef.close({ status: true, data: { formula: this.formula, label: this.labelName, isCurveBall: this.isCurveBall, value: this.labelValue, id: this.data.data.id } })
      else
        this.dialogRef.close({ status: true, data: { formula: this.formula, label: this.labelName, isCurveBall: this.isCurveBall, value: this.labelValue } })
    }
    else {
      this.toast.error("Invalid Formula, Please Recorrect the Formula", '', { timeOut: 2000 })
    }

  }

  checkLabel(event: any): boolean {
    let k = event.charCode || event.keyCode;

    if (k >= 48 && k <= 57) return true;

    return false;
  }
}